<template>
  <v-snackbar class="mb-8 sc-snackbar" :color="success ? 'green' : 'alert'" v-model="$store.state.infos.display" :timeout="timeout">
    <div class="d-flex">
      <div class="content-container wi-100 d-flex flex-column justify-center align-center">
        <span>{{content.text}}</span>
        <v-btn v-if="content.btn && content.cb" @click="content.cb" :color="content.color" class="mt-2" small>{{content.btn}}</v-btn>
      </div>
      <v-divider vertical></v-divider>
    </div>
    <template v-slot:action="{ attrs }">
      <v-btn color="white" icon v-bind="attrs" @click="$store.dispatch('close_infos')"><v-icon>mdi-close</v-icon></v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: ['content', 'timeout', 'success'],
  name: 'snackbar'
}
</script>

<style lang="scss">
.sc-snackbar {
  .v-snack__content {
    padding: 0;
    .content-container {
      padding: 14px 16px;
      white-space: nowrap;
    }
  }
  .v-snack__action {
    margin-left: 8px;
  }
}
</style>
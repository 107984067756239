<template>
  <div class="booster-wrapper">
    <div class="booster">
      <img class="booster-image" :src="deck.booster" :alt="deck.title"/>
      <div class="holo-wrapper">
        <div class="holo"></div>
        <div class="holo-after"></div>
      </div>
      <div class="highlight"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['deck'],
  name: 'booster',
  data() {
    return {
      cardWrapper: null,
      image: null,
      booster: null,
      highlight: null,
      holoWrapper: null,
      holo: null,
      holoAfter: null,
      // Angles
      mostX: -15,
      mostY: 15,
      rotationY: null,
      rotationX: null
    }
  },
  mounted() {
    this.cardWrapper = document.querySelector(".booster-wrapper")
    this.image = document.querySelector(".booster-wrapper .booster-image")
    this.booster = document.querySelector(".booster-wrapper .booster")
    this.highlight = document.querySelector(".booster-wrapper .highlight")
    this.holoWrapper = document.querySelector(".booster-wrapper .holo-wrapper")
    this.holo = document.querySelector(".booster-wrapper .holo")
    this.holoAfter = document.querySelector(".booster-wrapper .holo-after")
  
    this.cardWrapper.addEventListener("mousemove", this.moove)
    this.cardWrapper.addEventListener("mouseleave", this.leave)
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.moove);
    window.removeEventListener('mouseleave', this.leave);
  },
  methods: {
    moove(e) {
      this.booster.style.transition = "transform 0.15s ease"
      this.highlight.style.transition = "all 0.15s ease-in-out"
      this.holoWrapper.style.transition = "all 0.15s ease"
      this.holo.style.transition = "all 0.15s ease"
      this.holoAfter.style.transition = "all 0.15s ease"

      const x = e.offsetX
      const y = e.offsetY
      const { width, height } = this.cardWrapper.getBoundingClientRect()
      const halfWidth = width / 2
      const halfHeight = height / 2

      // Calculate angle
      this.rotationY = ((x - halfWidth) / halfWidth) * this.mostX
      this.rotationX  = ((y - halfHeight) / halfHeight) * this.mostY

      // Set rotation
      this.booster.style.transform = `rotateY(${this.rotationY}deg) rotateX(${this.rotationX}deg)`
      this.highlight.style.left = `${(this.rotationY / this.mostX) * 30 * -1}%`
      this.highlight.style.top = `${(this.rotationX / this.mostY) * 30 * -1}%`
      this.holoWrapper.style.opacity = 1

      // Holo effect
      const percentage = Math.abs(this.rotationY-this.rotationX)*100/28
      const ratio = percentage*0.26/100
      this.holoWrapper.style.filter = `brightness(${0.4+ratio}) contrast(2) saturate(1.5)`
      const percentageX = x*100/width
      const percentageY = y*100/height
        // Between 35% and 65%
      const ratioX = percentageX*30/100
      const ratioY = percentageY*30/100
      this.holo.style.backgroundImage = `url('https://res.cloudinary.com/fregonatristan/image/upload/v1679587005/SoulsCards/Decks/rare_jixaw5.jpg'), repeating-linear-gradient(0deg, rgb(255, 102, 102) 5%, rgb(255, 255, 102) 10%, rgb(102, 255, 102) 15%, rgb(102, 255, 255) 20%, rgb(102, 102, 255) 25%, rgb(255, 102, 255) 30%, rgb(255, 102, 102) 35%), repeating-linear-gradient(125deg, rgb(14, 21, 47) 0%, rgb(143, 163, 163) 4%, rgb(143, 193, 193) 4.5%, rgb(143, 163, 163) 5%, rgb(14, 21, 47) 10%, rgb(14, 21, 47) 12%), radial-gradient(circle at ${percentageX}% ${percentageY}%, rgba(0, 0, 0, 0.1) 12%, rgba(0, 0, 0, 0.15) 20%, rgba(0, 0, 0, 0.25) 120%)`
      this.holo.style.backgroundPosition = `center center, 0% ${35+ratioX}%, ${35+ratioY}% ${35+ratioX}%`
      this.holoAfter.style.backgroundImage = `url('https://res.cloudinary.com/fregonatristan/image/upload/v1679587005/SoulsCards/Decks/rare_jixaw5.jpg'), repeating-linear-gradient(0deg, rgb(255, 102, 102) 5%, rgb(255, 255, 102) 10%, rgb(102, 255, 102) 15%, rgb(102, 255, 255) 20%, rgb(102, 102, 255) 25%, rgb(255, 102, 255) 30%, rgb(255, 102, 102) 35%), repeating-linear-gradient(125deg, rgb(14, 21, 47) 0%, rgb(143, 163, 163) 4%, rgb(143, 193, 193) 4.5%, rgb(143, 163, 163) 5%, rgb(14, 21, 47) 10%, rgb(14, 21, 47) 12%), radial-gradient(circle at ${percentageX}% ${percentageY}%, rgba(0, 0, 0, 0.1) 12%, rgba(0, 0, 0, 0.15) 20%, rgba(0, 0, 0, 0.25) 120%)`
      this.holoAfter.style.backgroundPosition = `center center, 0% ${35+ratioX}%, ${35+ratioY}% ${35+ratioX}%`
    },
    leave() {
      this.booster.style.transition = "transform 0.5s ease"
      this.booster.style.transform = `rotateY(${this.rotationY*-1/2}deg) rotateX(${this.rotationX*-1/2}deg)`
      setTimeout(() => { 
        this.booster.style.transform = `rotateY(${this.rotationY/2}deg) rotateX(${this.rotationX/2}deg)`
      },500)
      setTimeout(() => { 
        this.booster.style.transform = `rotateY(0) rotateX(0)`
      },1000)

      this.highlight.style.transition = "left 0.5s ease-in-out, top 0.5s ease-in-out"
      this.highlight.style.left = `-20%`
      this.highlight.style.top = `-13%`

      this.holoWrapper.style.transition = "all 0.5s ease"
      this.holoWrapper.style.opacity = 0.3
      this.holoWrapper.style.filter = `brightness(0.7) contrast(2) saturate(1.5)`
      this.holo.style.transition = "all 0.5s ease"
      this.holo.style.backgroundImage = `url('https://res.cloudinary.com/fregonatristan/image/upload/v1679587005/SoulsCards/Decks/rare_jixaw5.jpg'), repeating-linear-gradient(0deg, rgb(255, 102, 102) 5%, rgb(255, 255, 102) 10%, rgb(102, 255, 102) 15%, rgb(102, 255, 255) 20%, rgb(102, 102, 255) 25%, rgb(255, 102, 255) 30%, rgb(255, 102, 102) 35%), repeating-linear-gradient(125deg, rgb(14, 21, 47) 0%, rgb(143, 163, 163) 4%, rgb(143, 193, 193) 4.5%, rgb(143, 163, 163) 5%, rgb(14, 21, 47) 10%, rgb(14, 21, 47) 12%), radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.1) 12%, rgba(0, 0, 0, 0.15) 20%, rgba(0, 0, 0, 0.25) 120%)`
      this.holo.style.backgroundPosition = `center center, 0% 50%, 50% 50%`
    },
    flipping(flipped) {
      if(flipped) {
        this.cardWrapper.style.transform = 'rotateY(0deg)'
        setTimeout(() => {
          this.image.src = this.deck.booster
          this.image.style.transform = 'scaleX(1)'
        }, 150)
      } else {
        this.cardWrapper.style.transform = 'rotateY(180deg)'
        setTimeout(() => {
          this.image.src = this.deck.booster_back
          this.image.style.transform = 'scaleX(-1)'
        }, 100)
      }
    }
  }
}
</script>

<style lang="scss">
.booster-wrapper, .booster-wrapper .booster, .booster-wrapper .booster-image {
  height: 100%;
}
.booster-wrapper {
  perspective: 1000px;
  transition: transform 0.5s ease;
  * {
    pointer-events: none;
  }
  .booster {
    position: relative;
    overflow: hidden;
    backface-visibility: hidden;
    clip-path: polygon(3% 9%, 1% 5%, 1% 0, 99% 0, 99% 5%, 97% 9%, 97% 91%, 100% 95%, 100% 100%, 0 100%, 0 95%, 3% 91%);
    img {
      object-fit: cover;
    }
    .highlight {
      position: absolute;
      height: 400px;
      width: 400px;
      border-radius: 50%;
      background-color: rgb(255, 255, 255, 0.05);
      filter: brightness(70%) blur(20px);
      left: -20%;
      top: -13%;
    }
    .holo-wrapper {
      opacity: 0.3;
      filter: brightness(0.7) contrast(2) saturate(1.5);
      mix-blend-mode: color-dodge;
      position: absolute;
      top: 0px;
      width: 100%;
      bottom: 0px;
      * {
        background-repeat: repeat;
      }
      .holo {
        background-image: url('https://res.cloudinary.com/fregonatristan/image/upload/v1679587005/SoulsCards/Decks/rare_jixaw5.jpg'),
        repeating-linear-gradient(0deg, rgb(255, 102, 102) 5%, rgb(255, 255, 102) 10%, rgb(102, 255, 102) 15%, rgb(102, 255, 255) 20%, rgb(102, 102, 255) 25%, rgb(255, 102, 255) 30%, rgb(255, 102, 102) 35%), 
        repeating-linear-gradient(125deg, rgb(14, 21, 47) 0%, rgb(143, 163, 163) 4%, rgb(143, 193, 193) 4.5%, rgb(143, 163, 163) 5%, rgb(14, 21, 47) 10%, rgb(14, 21, 47) 12%), 
        radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.1) 12%, rgba(0, 0, 0, 0.15) 20%, rgba(0, 0, 0, 0.25) 120%);
        background-position: center center, 0% 50%, 50% 50%;
        transform-style: preserve-3d;
        transform: translateZ(1px);
        position: absolute;
        top: 0px;
        width: 100%;
        bottom: 0px;
        z-index: 2;
        background-blend-mode: exclusion, hue, hard-light, exclusion;
        background-size: 50%, 200% 700%, 300% 100%, 200% 100%;
      }
      .holo-after {
        background-image: url('https://res.cloudinary.com/fregonatristan/image/upload/v1679587005/SoulsCards/Decks/rare_jixaw5.jpg'), 
        repeating-linear-gradient(0deg, rgb(255, 102, 102) 5%, rgb(255, 255, 102) 10%, rgb(102, 255, 102) 15%, rgb(102, 255, 255) 20%, rgb(102, 102, 255) 25%, rgb(255, 102, 255) 30%, rgb(255, 102, 102) 35%), 
        repeating-linear-gradient(125deg, rgb(14, 21, 47) 0%, rgb(143, 163, 163) 4%, rgb(143, 193, 193) 4.5%, rgb(143, 163, 163) 5%, rgb(14, 21, 47) 10%, rgb(14, 21, 47) 12%), 
        radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.1) 12%, rgba(0, 0, 0, 0.15) 20%, rgba(0, 0, 0, 0.25) 120%);
        background-position: center center, 0% 50%, 50% 50%;
        filter: brightness(0.7) contrast(1.6) saturate(1.4);
        transform-style: preserve-3d;
        transform: translateZ(1px);
        position: absolute;
        top: 0px;
        width: 100%;
        bottom: 0px;
        z-index: 2;
        mix-blend-mode: exclusion;
        background-blend-mode: exclusion, hue, hard-light, exclusion;
        background-size: 50%, 200% 400%, 147% 100%, 200% 100%;
      }
    }
  }
}
</style>
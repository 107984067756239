import Vue from "vue"
import Vuex from "vuex"
import status from './loader.js'
import infos from './infos.js'
import dialog from './dialog.js'
import auth from './auth.js'
import user from './user.js'
import socketio from './socketio.js'
import notif from './notif.js'
import navigation from './navigation.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    status,
    infos,
    dialog,
    auth,
    user,
    socketio,
    notif,
    navigation
  }
})

// Tools
import GlobalLoader from './tools/GlobalLoader'
import PageTemplate from './tools/PageTemplate'
import Logout from './tools/Logout'
import Submit from './tools/Submit'
import ImgUploader from './tools/ImgUploader'
import Filters from './tools/Filters'
import Snackbar from './tools/Snackbar'
import Dialog from './tools/Dialog'
import Pagination from './tools/Pagination'
import UsersTable from './tools/UsersTable'
import FriendRequests from './tools/FriendRequests'
import DecksTrade from './tools/DecksTrade'
import DataTrade from './tools/DataTrade'
import Notification from './tools/Notification'
// Navigation
import NavBar from './navigation/NavBar'
import Drawer from './navigation/Drawer'
import Footer from './navigation/Footer'
// Design
import Card from './design/Card'
import Booster from './design/Booster'
import Deck from './design/Deck'
import CardMiniature from './design/CardMiniature'
import BoosterMiniature from './design/BoosterMiniature'
import LoadingCard from './design/LoadingCard'
import Opening from './design/Opening'
import UserDeck from './design/UserDeck'


export default {
  install(Vue){
      Vue.mixin({
          created() {
              const jcomp = {Logout, Submit, ImgUploader, Filters, Snackbar, Dialog, Pagination, UsersTable, FriendRequests, DecksTrade, DataTrade, Notification, PageTemplate, GlobalLoader, NavBar, Drawer, Footer, Card, Booster, Deck, CardMiniature, BoosterMiniature, LoadingCard, Opening, UserDeck}
              for(let key in jcomp){
                  Vue.component('sc'+key,jcomp[key])
              }
          }
      })
  }
}
const state = {
  contentLoading: false,
  globalLoading: false
}

const actions = {
  start_g_loading: ({ commit }) => {
    commit('start_g_loading')
  },
  stop_g_loading: ({ commit }) => {
    commit('stop_g_loading')
  },
  start_c_loading: ({ commit }) => {
    commit('start_c_loading')
  },
  stop_c_loading: ({ commit }) => {
    commit('stop_c_loading')
  }
}

const mutations = {
  ['start_g_loading']: state => {
    state.globalLoading = true
  },
  ['stop_g_loading']: state => {
    state.globalLoading = false
  },
  ['start_c_loading']: state => {
    state.contentLoading = true
  },
  ['stop_c_loading']: state => {
    state.contentLoading = false
  }
}

export default {
  state,
  actions,
  mutations
}
<template>
  <v-app-bar id="navbar" app :class="{'scrolling':scrolling}">
    <v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" class="ma-0" color="white" @click.stop="$emit('drawer')"></v-app-bar-nav-icon>
    <router-link class="d-flex align-center" to="/">
      <img class="logo mr-2" :src="`/img/${$vuetify.breakpoint.smAndDown?'logo_responsive':'souls_cards_logo'}.png`" alt="Souls Cards logo"/>
    </router-link>
    <v-spacer></v-spacer>
    <router-link class="d-none d-md-block nav-item mx-4 text-h6 font-weight-bold text-uppercase" v-for="item in navItems" :key="item.link" :to="item.link">{{item.text}}</router-link>
    <v-spacer></v-spacer>
    <div v-if="!$store.getters.isAuthenticated" class="d-flex">
      <router-link class="d-block nav-item mx-2 text-h6 font-weight-bold" to="/login">LOGIN</router-link>
      <router-link class="d-block nav-item mx-2 text-h6 font-weight-bold" to="/register">REGISTER</router-link>
    </div>
    <div v-else-if="$store.state.auth.status === 'loading' || $store.state.user.status == 'loading'" class="d-flex align-center">
      <v-skeleton-loader class="skeleton-coins ma-0 mr-2" type="text"></v-skeleton-loader>
      <v-skeleton-loader type="avatar"></v-skeleton-loader>
    </div>
    <div v-else class="d-flex align-center">
      <span>{{getUserCoins}} coins</span>
      <img class="icon-image ml-2 mb-1 mr-2 mr-md-4" src="/img/font/coins.png" alt="icon"/>
      <v-menu :close-on-content-click="$vuetify.breakpoint.smAndDown ? false : true" content-class="nav-account-menu elevation-0" bottom transition="slide-y-transition" nudge-bottom="50">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip :disabled="$vuetify.breakpoint.smAndDown" bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                <div class="icon-profile">
                  <v-badge :value="$store.getters.getNotificationsNbr > 0" :content="$store.getters.getNotificationsNbr" offset-x="15" offset-y="15" color="red" overlap>
                    <v-avatar>
                      <img :src="$store.getters.getIcon || '/img/icon-default.jpg'" alt="User's icon"/>
                    </v-avatar>
                  </v-badge>
                </div>
              </v-btn>
            </template>
            <span>Your profile</span>
          </v-tooltip>
        </template>
        <div class="nav-account bg-main text-main ma-2 pa-2 rounded-lg">
          <div class="d-flex account-items rounded-lg">
            <v-badge v-for="(item, index) in accountMenus" :key="item.title" :value="item.notifs > 0" :content="item.notifs" :class="[{'hovered':indexHover === index},'account-item']" color="red" overlap>
              <img @click="$router.currentRoute.path !== item.link && $router.push(item.link)" @mouseover="indexHover = index" :src="`/img/icons/${item.title}.png`" :alt="item.title"/>
            </v-badge>
          </div>
          <p class="my-2 text-center text-uppercase text-sc">{{accountHover.title}}</p>
          <p class="mb-2 text-center">{{accountHover.description}}</p>
          <v-divider class="mt-0 mb-2"></v-divider>
          <sc-logout></sc-logout>
        </div>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  name: 'nav-bar',
  props: ['navItems'],
  data() {
    return {
      scrolling: false,
      indexHover: 0
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      this.scrolling = window.top.scrollY !== 0
    }
  },
  computed: {
    accountHover() {
      return this.accountMenus[this.indexHover]
    },
    accountMenus() {
      return [
        { link: '/profile/cards', title: 'cards', description: 'See your decks' },
        { link: '/profile/trades', title: 'trades', description: 'Manage your trades', notifs: this.$store.getters.getNotifications && this.$store.getters.getNotifications.trades.length },
        { link: '/profile/friends', title: 'friends', description: 'All your friends', notifs: this.$store.getters.getNotifications && this.$store.getters.getNotifications.friendRequests.length },
        { link: '/profile/historic', title: 'historic', description: 'Your historic', notifs: this.$store.getters.getNotifications && this.$store.getters.getNotifications.historic.length },
        { link: '/profile', title: 'profile', description: 'View and setting your profile' }
      ]
    },
    getUserCoins() {
      return this.$store.getters.getCoins > 9999 ? '+9999' : this.$store.getters.getCoins
    }
  }
}
</script>

<style lang="scss">
.v-menu__content {
  z-index: 10000!important;
}
#navbar {
  background: rgba($blacklight,0.80)!important;
  transition: all ease 0.3s;
  height: 76px!important;
  .logo {
    height: 24px;
  }
  .skeleton-coins {
    width: 5rem;
    .v-skeleton-loader__text {
      height: 1.5rem;
      margin-bottom: 0;
    }
  }
  .avatar {
    height: 36px;
    border-radius: 100%;
  }
  .v-toolbar {
    &__content {
      transition: all ease 0.3s;
      height: 76px!important;
      padding-right: 3rem;
      padding-left: 3rem;
      @include mobile {
        padding-left: 1rem;
        padding-right: 2rem;
      }
    }
  }
  &.scrolling {
      height: 64px!important;
    .v-toolbar__content {
      height: 64px!important;
    }
  }
  .nav-item {
    position: relative;
    &:after {    
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      left: 50%;
      position: absolute;
      background: #fff;
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
      width: 0;
    }
    &:hover:after { 
      width: 100%; 
      left: 0; 
    }
  }
}
.icon-profile {
  display: flex;
  align-items: center;
  justify-content: center;
  border: transparent 2px solid;
  border-radius: 9999px;
  padding: 2px;
  transition: all 0.25s ease;
  &:hover {
    border: $secondary 2px solid;
  }
  .v-badge {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.nav-account-menu {
  max-width: 100%;
  .nav-account {
    border: solid $grey 2px;
    .account-item {
      border: solid transparent 2px;
      cursor: pointer;
      img {
        height: 62px;
      }
      .v-badge__badge {
        z-index: 100;
      }
      &s {
        min-width: calc(62px * 5);
        background-color: $grey;
      }
      @include mobile {
        img {
          height: 52px;
        }
        &s {
          min-width: calc(52px * 5);
        }
      }
      &.hovered {
        border: solid $secondary 2px;
        background-color: $grey;
      }
    }
  }
}
</style>
<template>
  <div id="loader">
    <v-progress-circular size="80" indeterminate color="secondary"></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: 'global-loader'
}
</script>

<style lang="scss">
#loader{
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.8);
    z-index: 1000000000;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
}
</style>
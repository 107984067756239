import { api } from '@/../config'
import axios from 'axios'
import router from '@/router'

const state = {
  token: localStorage.getItem('user-token') || '',
  status: '',
  hasLoadedOnce: false
}

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status
} 
  
const actions = {
  AUTH_REQUEST: ({ commit, dispatch }, payload) => {
    return new Promise(async (resolve, reject) => {
      commit('AUTH_REQUEST')
      try {
        const res = await axios.post(`${api}public/auth`, payload)
        localStorage.setItem('user-token', res.data.token)
        // set axios header to the token value.
        axios.defaults.headers.common['Authorization'] = res.data.token
        setTimeout(() => {
          commit('AUTH_SUCCESS', res.data.token)
          commit('USER_SUCCESS', res.data.profile)
          resolve(res.data)
        }, 1000)
      } catch(err) {
        commit('AUTH_ERROR', err)
        localStorage.removeItem('user-token')
        reject(err.response || err)
      }
    })
  },
  AUTH_LOGOUT: async ({ commit, dispatch }) => {
    try{ 
      commit('USER_LOGOUT')
      commit('STOP_SOCKET')
      if(['/profile','/profile/trades','/profile/historic','/profile/friends','/profile/cards'].includes(router.currentRoute.path)) router.push('/')
      await axios.post(`${api}auth/logout`)
    } catch (e) {
      console.log(e)
    } finally {
      commit('AUTH_LOGOUT')
      axios.defaults.headers.common['Authorization'] = ''
      localStorage.removeItem('user-token')
      dispatch('display_infos', {text: 'Your session has expired.'})
    }
  },
  UPDATE_TOKEN: ({ commit }, token) => {
    axios.defaults.headers.common['Authorization'] = token
    localStorage.setItem('user-token', token)
    commit('AUTH_SUCCESS',token)
    return true
  },
  GET_RECAPTCHA: async ({ commit }, payload) => {
    await payload['1']()
    return await payload['2'](payload['3'])
  }
}
  
const mutations = {
  ['AUTH_REQUEST']: state => {
    state.status = 'loading'
  },
  ['AUTH_SUCCESS']: (state, token) => {
    state.status = 'success'
    state.token = token
    state.hasLoadedOnce = true
  },
  ['AUTH_ERROR']: state => {
    state.status = 'error'
    state.hasLoadedOnce = true
  },
  ['AUTH_LOGOUT']: state => {
    state.token = ''
  }
}
  
export default {
  state,
  getters,
  actions,
  mutations
}
  
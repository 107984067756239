<template>
  <v-navigation-drawer :value="value" app temporary class="navigation-drawer" @input="$emit('input', $event)" width="60%">
    <i class="closeIcon" @click="$emit('input', false)"></i>
    <v-list>
      <v-list-item v-for="item in navItems" :key="item.link" @click="$emit('hidedrawer')" class="text-white" :to="item.link">
        <v-list-item-content>
            <v-list-item-title class="d-flex justify-center text-h6 font-weight-bold text-uppercase">
              {{item.text}}
            </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'drawer',
  props: ['navItems','value'],
  data() {
    return {
    }
  }
}
</script>

<style lang="scss">
.navigation-drawer {
  padding-top: 3.5rem;
  // @include closeIcon(rgb(120, 120, 120), 2rem, 0.1rem, 1rem, 1rem);
  background-color: $blacklight!important;
  z-index: 1000000;
  .nav-item {
    position: relative;
    &:after {    
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      left: 50%;
      position: absolute;
      background: #fff;
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
      width: 0;
    }
    &:hover:after { 
      width: 100%; 
      left: 0; 
    }
  }
}
</style>
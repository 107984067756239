import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VNavigationDrawer,{staticClass:"navigation-drawer",attrs:{"value":_vm.value,"app":"","temporary":"","width":"60%"},on:{"input":function($event){return _vm.$emit('input', $event)}}},[_c('i',{staticClass:"closeIcon",on:{"click":function($event){return _vm.$emit('input', false)}}}),_c(VList,_vm._l((_vm.navItems),function(item){return _c(VListItem,{key:item.link,staticClass:"text-white",attrs:{"to":item.link},on:{"click":function($event){return _vm.$emit('hidedrawer')}}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"d-flex justify-center text-h6 font-weight-bold text-uppercase"},[_vm._v(" "+_vm._s(item.text)+" ")])],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
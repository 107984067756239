import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next, cb) => {
  if (store.getters.isAuthenticated) {
    if(cb) return cb(to, from, next)
    next()
    return
  }
  store.dispatch('set_pending', to.fullPath)
  next('/login')
}

function meta(title){
  const hyphen = title ? ' - ':''
  return{
    title: `${title}${hyphen}Souls Cards`,
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: meta('Home')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/Register.vue'),
    beforeEnter: ifNotAuthenticated,
    meta: meta('Register')
  },
  {
    path: '/activate-account/:activationToken',
    name: 'Activate account',
    component: () => import('@/views/Activate.vue'),
    meta: meta('Activate Account')
  },
  {
    path: '/forgotten-password',
    name: 'Forgotten password',
    component: () => import('@/views/ForgottenPassword.vue'),
    meta: meta('Forgotten Password')
  },
  {
    path: '/forgotten-password/:passwordToken',
    name: 'Change password',
    component: () => import('@/views/ForgottenPassword.vue'),
    meta: meta('Change Password')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    beforeEnter: ifNotAuthenticated,
    meta: meta('Login')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/user/Profile.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Profile')
  },
  {
    path: '/profile/cards',
    name: 'Your Cards',
    component: () => import('@/views/user/Cards.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Your cards')
  },
  {
    path: '/profile/friends',
    name: 'Your Friends',
    component: () => import('@/views/user/Friends.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Your friends')
  },
  {
    path: '/profile/historic',
    name: 'Your historic',
    component: () => import('@/views/user/Historic.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Your historic')
  },
  {
    path: '/decks',
    name: 'Decks',
    component: () => import('@/views/Decks.vue'),
    meta: meta('Decks')
  },
  {
    path: '/cards',
    name: 'Cards',
    component: () => import('@/views/Cards.vue'),
    meta: meta('Cards')
  },
  {
    path: '/card/:cardTitle/:cardId',
    name: 'Card',
    component: () => import('@/views/Card.vue'),
    meta: meta('Card')
  },
  {
    path: '/marketplace',
    name: 'Marketplace',
    component: () => import('@/views/Market.vue'),
    meta: meta('Marketplace')
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('@/views/Users.vue'),
    meta: meta('Users')
  },
  {
    path: '/user/:username/:userId',
    name: 'User',
    component: () => import('@/views/User.vue'),
    meta: meta('User')
  },
  {
    path: '/new/trade/:userId',
    name: 'New trade',
    props: {viewOnly: false},
    component: () => import('@/views/Trade.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('New trade')
  },
  {
    path: '/trade/:tradeId',
    name: 'Trade',
    props: {viewOnly: true},
    component: () => import('@/views/Trade.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Trade')
  },
  {
    path: '/edit/trade/:tradeId',
    name: 'Edit trade',
    props: {viewOnly: false},
    component: () => import('@/views/Trade.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Edit trade')
  },
  {
    path: '/profile/trades',
    name: 'Trades',
    component: () => import('@/views/user/Trades.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Trades')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/questions/Contact.vue'),
    meta: meta('Contact')
  },
  {
    path: '/who-am-i',
    name: 'Who am I',
    component: () => import('@/views/questions/WhoAmI.vue'),
    meta: meta('Who am I')
  },
  {
    path: '/privacy-policy',
    name: 'Privacy policy',
    component: () => import('@/views/questions/PrivacyPolicy.vue'),
    meta: meta('Privacy Policy')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

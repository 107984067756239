<template>
  <div class="decks-trade f-column">
    <div v-if="!viewOnly">
    <v-divider class="mx-4"></v-divider>
      <p class="text-caption grey--text mx-4 my-0">Coins to exchange :</p>
      <div class="d-flex justify-center">
        <v-btn :disabled="coins < 1" @mousedown="changeCoins(-5)" @mouseup="unsetInter()" @mouseleave="unsetInter()" class="mr-2" icon><v-icon>mdi-minus</v-icon></v-btn>
        <div class="f-row f-center mx-2">
          <span>{{coins}}</span>
          <img class="icon-image ml-2 mb-1" src="/img/font/coins.png" alt="Coins"/>
        </div>
        <v-btn :disabled="coins >= maxCoins" @mousedown="changeCoins(5)" @mouseup="unsetInter()" @mouseleave="unsetInter()" class="ml-2" icon><v-icon>mdi-plus</v-icon></v-btn>
      </div>
    </div>
    <v-divider class="mx-4"></v-divider>
    <v-slide-group class="" v-model="deckIndex" show-arrows>
      <v-slide-item v-for="(deck, index) in decks" :key="deck._id">
        <v-btn @click="deckIndex = index" :class="[{'active secondary':deckIndex===index},'my-4 mx-2']">
          <img class="icon-image" :src="deck.logo" :alt="deck.title"/>
        </v-btn>
      </v-slide-item>
    </v-slide-group>
    <p class="px-4 text-h5 mt-4 mb-0">Cards from <span class="text-sc secondary--text">{{decks[deckIndex].title}}</span>'s deck :</p>
    <p class="px-4 text-caption mb-4">(Cards' numbers corresponding to numbers after the trade)</p>
    <div v-if="decks[deckIndex].cards.length" class="d-flex flex-wrap justify-space-around">
      <div v-for="(card, cardIndex) in decks[deckIndex].cards" :key="card._id" class="pa-2 pa-md-6 f-column align-center">
        <router-link :to="`/card/${card.title.replaceAll(' ', '-')}/${card._id}`">
          <sc-card-miniature :card="card" :active="card.nbr"></sc-card-miniature>
        </router-link>
        <p class="my-4 text-sc"><span class="text-caption">x</span> {{card.nbr || 0}}</p>
        <v-btn v-if="card.nbr && !viewOnly" @click="$emit('addCard', {userField, card, deckIndex, cardIndex})" class="secondary--text" outlined>add x1</v-btn>
        <v-btn v-else-if="!viewOnly" disabled outlined>no more</v-btn>
      </div>
      <div class="card-miniature-plus" v-for="flexItem in 5" :key="flexItem"></div>
    </div>
    <p v-else class="my-2 my-md-6 text-center text-caption text-sc">No card for this deck</p>
  </div>
</template>

<script>
export default {
  props: ['decks','coins','maxCoins','userField','viewOnly'],
  name: 'decks-trade',
  data() {
    return {
      deckIndex: 0,
      inter: null
    }
  },
  methods: {
    unsetInter(){
      clearInterval(this.inter)
    },
    changeCoins(value) {
      this.$emit('changeCoins', {userField: this.userField, value})
      if(value === 5 && this.coins >= 0 && this.coins < (this.maxCoins - 6) || value === -5 && this.coins > 5 && this.coins <= this.maxCoins) {
        this.inter = setTimeout(() => {this.changeCoins(value)}, 250)
      }
    }
  }
}
</script>

<style lang="scss">
.decks-trade {
  width: 30vw;
  @include noDesktop {
    width: 100%;
  }
  .card-miniature-plus {
    width: calc(200px + 48px);
    @include noDesktop {
      width: calc(150px + 16px);
    }
  }
  p {
    white-space: break-spaces;
  }
}
</style>
<template>
  <div class="user-deck flex-grow-1">
    <v-slide-group class="decks-list" v-model="activeDeckIndex" show-arrows>
      <v-slide-item v-for="(deck, index) in decks" :key="deck._id">
        <div :class="[{'active':activeDeck._id===deck._id},'deck-container cursor-pointer rounded-lg my-8 mx-4']" @click="selectDeck(index)">
          <img v-for="index in 4" :key="index" class="active-corner" src="/img/btn/corner.svg" alt="Corner style"/>
          <sc-booster-miniature :deck="deck"></sc-booster-miniature>
        </div>
      </v-slide-item>
    </v-slide-group>
    <div class="deck-cards pa-4">
      <p class="text-h5 mb-2">{{user ? `${user.username}'s` : 'Your'}} cards from <span class="text-sc secondary--text">{{activeDeck.title}}</span>'s deck :</p>
      <div class="d-flex flex-wrap justify-space-around">
        <div v-for="card in activeDeck.cards" :key="card._id" class="pa-2 pa-md-6">
          <router-link :to="`/card/${card.title.replaceAll(' ', '-')}/${card._id}`">
            <sc-card-miniature :card="card" :active="card.nbr"></sc-card-miniature>
          </router-link>
          <div v-if="card.nbr" class="d-flex align-center justify-center mt-2">
            <p class="mb-0 text-sc">{{card.nbr}}</p>
          </div>
          <p v-else class="text-sc text-center mt-2 mb-0">locked</p>
        </div>
        <div class="card-miniature-plus" v-for="flexItem in 5" :key="flexItem"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['decks','user'],
  name: 'user-deck',
  data() {
    return {
      activeDeckIndex: 0
    }
  },
  methods: {
    selectDeck(index) {
      this.activeDeckIndex = index
    }
  },
  computed: {
    activeDeck() {
      return this.decks[this.activeDeckIndex]
    }
  }
}
</script>

<style lang="scss">
.user-deck {
  .decks-list {
    height: 40vh;
    .deck-container {
      padding: 1rem;
      position: relative;
      transition: all ease 0.25s;
      &:hover {
        background-color: $blacklight;
      }
      .booster-miniature {
        height: 100%;
      }
      &.active {
        .active-corner {
          opacity: 1;
        }
      }
      .active-corner {
        position: absolute;
        height: 1.5em;
        width: 1.5em;
        opacity: 0;
        transition: all ease 0.25s;
        &:first-child {
          top: 0.50em;
          left: 0.50em;
          transform: rotate(90deg);
        }
        &:nth-child(2) {
          top: 0.50em;
          right: 0.50em;
          transform: rotate(180deg);
        }
        &:nth-child(3) {
          bottom: 0.50em;
          right: 0.50em;
          transform: rotate(-90deg);
        }
        &:nth-child(4) {
          bottom: 0.50em;
          left: 0.50em;
        }
      }
    }
  }
  .deck-cards {
    background-color: $blacklight;
    .card-miniature-plus {
      width: calc(200px + 48px);
      @include mobile {
        width: calc(150px + 16px);
      }
    }
  }
}
</style>
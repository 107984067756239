<template>
  <div :class="['card-wrapper',`card-${index}`]">
    <div :class="[{'full-template':card.rarity.full,'forced-mobile':mobile},'card rounded-lg']" :style="`--deck-color-dark:${hex2rgba(card.deck.color, -50)};--deck-color-light:${hex2rgba(card.deck.color, 25)};`">
      <div class="card-content rounded-lg">
        <img class="wi-100 card-image" :src="card.image" :alt="card.title"/>
        <div v-if="front" class="card-infos">
          <p class="card-title text-sc">{{card.title}}</p>
          <div class="d-flex">
            <span class="card-chip category"><img :src="card.category.icon"/>{{card.category.title}}</span>
            <span class="card-chip rarity"><img :src="card.rarity.icon"/>{{card.rarity.title}}</span>
          </div>
        </div>
      </div>
      <div :class="[getRarity,'holo-wrapper']">
        <div class="holo"></div>
        <div class="holo-after"></div>
      </div>
      <div class="highlight"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['card','index','mobile'],
  name: 'card',
  data() {
    return {
      holoTemplates: [
        {slug: 'rare', effect: 'https://res.cloudinary.com/fregonatristan/image/upload/v1679587005/SoulsCards/Decks/rare_jixaw5.jpg'}, 
        {slug: 'extra rare', effect: 'https://res.cloudinary.com/fregonatristan/image/upload/v1679586401/SoulsCards/Decks/extra_rare_s2gvc2.jpg'}, 
        {slug: 'matt', effect: 'https://res.cloudinary.com/fregonatristan/image/upload/v1679587006/SoulsCards/Decks/mat_lswwdq.jpg'}
      ],
      cardWrapper: null,
      front: true,
      image: null,
      cardEl: null,
      cardContent: null,
      highlight: null,
      holoWrapper: null,
      holo: null,
      holoAfter: null,
      // Angles
      mostX: -15,
      mostY: 15,
      rotationY: null,
      rotationX: null
    }
  },
  mounted() {
    this.cardWrapper = document.querySelector(`.card-wrapper${this.classId}`)
    this.image = document.querySelector(`.card-wrapper${this.classId} .card-image`)
    this.cardEl = document.querySelector(`.card-wrapper${this.classId} .card`)
    this.cardContent = document.querySelector(`.card-wrapper${this.classId} .card .card-content`)
    this.highlight = document.querySelector(`.card-wrapper${this.classId} .highlight`)
    this.holoWrapper = document.querySelector(`.card-wrapper${this.classId} .holo-wrapper`)
    this.holo = document.querySelector(`.card-wrapper${this.classId} .holo`)
    this.holoAfter = document.querySelector(`.card-wrapper${this.classId} .holo-after`)
  
    this.cardWrapper.addEventListener("mousemove", this.moove)
    this.cardWrapper.addEventListener("mouseleave", this.leave)
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.moove);
    window.removeEventListener('mouseleave', this.leave);
  },
  methods: {
    hex2rgba(col, amt) {
      col = col.replace(/^#/, '')
      if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]
      let [r, g, b] = col.match(/.{2}/g);
      ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])
      r = Math.max(Math.min(255, r), 0).toString(16)
      g = Math.max(Math.min(255, g), 0).toString(16)
      b = Math.max(Math.min(255, b), 0).toString(16)
      const rr = (r.length < 2 ? '0' : '') + r
      const gg = (g.length < 2 ? '0' : '') + g
      const bb = (b.length < 2 ? '0' : '') + b
      return `#${rr}${gg}${bb}`
    },
    moove(e) {
      this.cardEl.style.transition = "transform 0.15s ease"
      this.highlight.style.transition = "all 0.15s ease-in-out"
      this.holoWrapper.style.transition = "all 0.15s ease"
      this.holo.style.transition = "all 0.15s ease"
      this.holoAfter.style.transition = "all 0.15s ease"

      const x = e.offsetX
      const y = e.offsetY
      const { width, height } = this.cardWrapper.getBoundingClientRect()
      const halfWidth = width / 2
      const halfHeight = height / 2

      // Calculate angle
      this.rotationY = ((x - halfWidth) / halfWidth) * this.mostX
      this.rotationX  = ((y - halfHeight) / halfHeight) * this.mostY

      // Set rotation
      this.cardEl.style.transform = `rotateY(${this.rotationY}deg) rotateX(${this.rotationX}deg)`
      this.highlight.style.left = `${(this.rotationY / this.mostX) * 30 * -1}%`
      this.highlight.style.top = `${(this.rotationX / this.mostY) * 30 * -1}%`
      if(this.isHolo) {
        // Holo effect
        this.holoWrapper.style.opacity = 1
        const percentage = Math.abs(this.rotationY-this.rotationX)*100/28
        const ratio = percentage*0.26/100
        this.holoWrapper.style.filter = `brightness(${0.4+ratio}) contrast(2) saturate(1.5)`
        const percentageX = x*100/width
        const percentageY = y*100/height
        // Between 35% and 65%
        const ratioX = percentageX*30/100
        const ratioY = percentageY*30/100
        this.holo.style.backgroundImage = `url(${this.holoTemplateActive}), repeating-linear-gradient(0deg, rgb(255, 102, 102) 5%, rgb(255, 255, 102) 10%, rgb(102, 255, 102) 15%, rgb(102, 255, 255) 20%, rgb(102, 102, 255) 25%, rgb(255, 102, 255) 30%, rgb(255, 102, 102) 35%), repeating-linear-gradient(125deg, rgb(14, 21, 47) 0%, rgb(143, 163, 163) 4%, rgb(143, 193, 193) 4.5%, rgb(143, 163, 163) 5%, rgb(14, 21, 47) 10%, rgb(14, 21, 47) 12%), radial-gradient(circle at ${percentageX}% ${percentageY}%, rgba(0, 0, 0, 0.1) 12%, rgba(0, 0, 0, 0.15) 20%, rgba(0, 0, 0, 0.25) 120%)`
        this.holo.style.backgroundPosition = `center center, 0% ${35+ratioX}%, ${35+ratioY}% ${35+ratioX}%`
        this.holoAfter.style.backgroundImage = `url(${this.holoTemplateActive}), repeating-linear-gradient(0deg, rgb(255, 102, 102) 5%, rgb(255, 255, 102) 10%, rgb(102, 255, 102) 15%, rgb(102, 255, 255) 20%, rgb(102, 102, 255) 25%, rgb(255, 102, 255) 30%, rgb(255, 102, 102) 35%), repeating-linear-gradient(125deg, rgb(14, 21, 47) 0%, rgb(143, 163, 163) 4%, rgb(143, 193, 193) 4.5%, rgb(143, 163, 163) 5%, rgb(14, 21, 47) 10%, rgb(14, 21, 47) 12%), radial-gradient(circle at ${percentageX}% ${percentageY}%, rgba(0, 0, 0, 0.1) 12%, rgba(0, 0, 0, 0.15) 20%, rgba(0, 0, 0, 0.25) 120%)`
        this.holoAfter.style.backgroundPosition = `center center, 0% ${35+ratioX}%, ${35+ratioY}% ${35+ratioX}%`
      }
    },
    leave() {
      this.cardEl.style.transition = "transform 0.5s ease"
      this.cardEl.style.transform = `rotateY(${this.rotationY*-1/2}deg) rotateX(${this.rotationX*-1/2}deg)`
      setTimeout(() => { 
        this.cardEl.style.transform = `rotateY(${this.rotationY/2}deg) rotateX(${this.rotationX/2}deg)`
      },500)
      setTimeout(() => { 
        this.cardEl.style.transform = `rotateY(0) rotateX(0)`
      },1000)

      this.highlight.style.transition = "left 0.5s ease-in-out, top 0.5s ease-in-out"
      this.highlight.style.left = `-20%`
      this.highlight.style.top = `-13%`
      if(this.isHolo) {
        this.holoWrapper.style.transition = "all 0.5s ease"
        this.holoWrapper.style.opacity = 0.3
        this.holoWrapper.style.filter = `brightness(0.7) contrast(2) saturate(1.5)`
        this.holo.style.transition = "all 0.5s ease"
        this.holo.style.backgroundImage = `url(${this.holoTemplateActive}), repeating-linear-gradient(0deg, rgb(255, 102, 102) 5%, rgb(255, 255, 102) 10%, rgb(102, 255, 102) 15%, rgb(102, 255, 255) 20%, rgb(102, 102, 255) 25%, rgb(255, 102, 255) 30%, rgb(255, 102, 102) 35%), repeating-linear-gradient(125deg, rgb(14, 21, 47) 0%, rgb(143, 163, 163) 4%, rgb(143, 193, 193) 4.5%, rgb(143, 163, 163) 5%, rgb(14, 21, 47) 10%, rgb(14, 21, 47) 12%), radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.1) 12%, rgba(0, 0, 0, 0.15) 20%, rgba(0, 0, 0, 0.25) 120%)`
        this.holo.style.backgroundPosition = `center center, 0% 50%, 50% 50%`
      }
    },
    flipping(flipped) {
      if(flipped) {
        this.cardWrapper.style.transform = 'rotateY(0deg)'
        setTimeout(() => {
          this.front = true
          this.image.src = this.card.image
          this.image.style.transform = 'scaleX(1)'
          this.image.style.borderRadius = '0'
          this.cardContent.style.display = 'block'
          this.cardContent.style.padding = this.$vuetify.breakpoint.mdAndUp ? '16px 16px 8px 16px' : '10px 10px 5px 10px'
        }, 150)
      } else {
        this.cardWrapper.style.transform = 'rotateY(180deg)'
        setTimeout(() => {
          this.front = false
          this.image.src = '/img/design/back-card.jpg'
          this.image.style.transform = 'scaleX(-1)'
          this.image.style.borderRadius = '8px'
          this.cardContent.style.display = 'flex'
          this.cardContent.style.padding = '0'
        }, 100)
      }
    }
  },
  computed: {
    holoTemplateActive() {
      return this.holoTemplates.find(r => { return r.slug === this.card.rarity.title }).effect
    },
    getRarity() {
      return this.card.rarity.title.replace(' ', '-')
    },
    isHolo() {
      return this.card.rarity.holo
    },
    classId() {
      return `.card-${this.index}`
    }
  }
}
</script>

<style lang="scss">
.card-wrapper {
  perspective: 1000px;
  transition: transform 0.5s ease;
  * {
    pointer-events: none;
  }
  &, .card, .card-content {
    height: 100%;
  }
  .card {
    position: relative;
    overflow: hidden;
    backface-visibility: hidden;
    padding: 2px;
    background-color: $secondary;
    img {
      object-fit: cover;
    }
    &.full-template .card-infos {
      padding: 0 25px 34px 25px;
    }
    &:not(.full-template) .card-infos {
      padding: 0 10px 5px 10px;
    }
    @include desktop {
      &:not(.forced-mobile) {
        padding: 4px;
        &.full-template .card-infos {
          padding: 0 40px 52px 40px;
        }
        &:not(.full-template) .card-infos {
          padding: 0 16px 8px 16px;
        }
        .card-content {
          padding: 16px 16px 8px 16px;
          .card-infos {
            .card-title {
              font-size: 25px;
              margin-bottom: 18px;
              padding-bottom: 8px;
              &:after { 
                height: 4px;
              }
            }
            .card-chip {
              font-size: 18px;
              padding: 0 8px;
              &.category {
                margin: 0 4px 0 8px;
              }
              &.rarity {
                margin-left: 4px;
              }
              &.rarity img, &.category img {
                height: 18px;
              }
            }
          }
        }
      }
    }
    .card-content {
      background: linear-gradient(180deg, var(--deck-color-light) 0%, var(--deck-color-dark) 100%);
      padding: 10px 10px 3px 10px;
      .card-infos {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        .card-title {
          text-shadow: 0px 0px 6px #000000;
          font-size: 15.66px;
          margin-bottom: 10px;
          padding-bottom: 8px;
          text-align: center;
          position: relative;
          &:after {    
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
            bottom: 0;
            content: "";
            display: block;
            height: 2.5px;
            left: 10%;
            position: absolute;
            background: #fff;
            width: 80%;
          }
        }
        .card-chip {
          border: 1px solid;
          font-size: 11.25px;
          padding: 0 5px;
          border-radius: 9999px;
          background: rgba(37, 37, 37, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          &.category {
            color: white;
            border-color: white;
            margin: 0 2.5px 0 5px;
          }
          &.rarity {
            color: $secondary;
            border-color: $secondary;
            margin-left: 2.5px;
          }
          &.rarity img, &.category img {
            margin-right: 2px;
            height: 11.25px;
          }
        }
      }
    }
    .highlight {
      position: absolute;
      height: 200px;
      width: 200px;
      border-radius: 50%;
      background-color: rgb(255, 255, 255, 0.05);
      filter: brightness(30%) blur(50px);
      left: -20%;
      top: -13%;
    }
    .holo-wrapper {
      opacity: 0.3;
      filter: brightness(0.7) contrast(2) saturate(1.5);
      mix-blend-mode: color-dodge;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      bottom: 0px;
      * {
        background-repeat: repeat;
      }
      &.rare {
        .holo {
          background-image: url('https://res.cloudinary.com/fregonatristan/image/upload/v1679587005/SoulsCards/Decks/rare_jixaw5.jpg'),
          repeating-linear-gradient(0deg, rgb(255, 102, 102) 5%, rgb(255, 255, 102) 10%, rgb(102, 255, 102) 15%, rgb(102, 255, 255) 20%, rgb(102, 102, 255) 25%, rgb(255, 102, 255) 30%, rgb(255, 102, 102) 35%), 
          repeating-linear-gradient(125deg, rgb(14, 21, 47) 0%, rgb(143, 163, 163) 4%, rgb(143, 193, 193) 4.5%, rgb(143, 163, 163) 5%, rgb(14, 21, 47) 10%, rgb(14, 21, 47) 12%), 
          radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.1) 12%, rgba(0, 0, 0, 0.15) 20%, rgba(0, 0, 0, 0.25) 120%);
        }
        .holo-after {
          background-image: url('https://res.cloudinary.com/fregonatristan/image/upload/v1679587005/SoulsCards/Decks/rare_jixaw5.jpg'), 
          repeating-linear-gradient(0deg, rgb(255, 102, 102) 5%, rgb(255, 255, 102) 10%, rgb(102, 255, 102) 15%, rgb(102, 255, 255) 20%, rgb(102, 102, 255) 25%, rgb(255, 102, 255) 30%, rgb(255, 102, 102) 35%), 
          repeating-linear-gradient(125deg, rgb(14, 21, 47) 0%, rgb(143, 163, 163) 4%, rgb(143, 193, 193) 4.5%, rgb(143, 163, 163) 5%, rgb(14, 21, 47) 10%, rgb(14, 21, 47) 12%), 
          radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.1) 12%, rgba(0, 0, 0, 0.15) 20%, rgba(0, 0, 0, 0.25) 120%);
        }
      }
      &.extra-rare {
        .holo {
          background-image: url('https://res.cloudinary.com/fregonatristan/image/upload/v1679586401/SoulsCards/Decks/extra_rare_s2gvc2.jpg'),
          repeating-linear-gradient(0deg, rgb(255, 102, 102) 5%, rgb(255, 255, 102) 10%, rgb(102, 255, 102) 15%, rgb(102, 255, 255) 20%, rgb(102, 102, 255) 25%, rgb(255, 102, 255) 30%, rgb(255, 102, 102) 35%), 
          repeating-linear-gradient(125deg, rgb(14, 21, 47) 0%, rgb(143, 163, 163) 4%, rgb(143, 193, 193) 4.5%, rgb(143, 163, 163) 5%, rgb(14, 21, 47) 10%, rgb(14, 21, 47) 12%), 
          radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.1) 12%, rgba(0, 0, 0, 0.15) 20%, rgba(0, 0, 0, 0.25) 120%);
        }
        .holo-after {
          background-image: url('https://res.cloudinary.com/fregonatristan/image/upload/v1679586401/SoulsCards/Decks/extra_rare_s2gvc2.jpg'), 
          repeating-linear-gradient(0deg, rgb(255, 102, 102) 5%, rgb(255, 255, 102) 10%, rgb(102, 255, 102) 15%, rgb(102, 255, 255) 20%, rgb(102, 102, 255) 25%, rgb(255, 102, 255) 30%, rgb(255, 102, 102) 35%), 
          repeating-linear-gradient(125deg, rgb(14, 21, 47) 0%, rgb(143, 163, 163) 4%, rgb(143, 193, 193) 4.5%, rgb(143, 163, 163) 5%, rgb(14, 21, 47) 10%, rgb(14, 21, 47) 12%), 
          radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.1) 12%, rgba(0, 0, 0, 0.15) 20%, rgba(0, 0, 0, 0.25) 120%);
        }
      }
      &.matt {
        .holo {
          background-image: url('https://res.cloudinary.com/fregonatristan/image/upload/v1679587006/SoulsCards/Decks/mat_lswwdq.jpg'),
          repeating-linear-gradient(0deg, rgb(112, 112, 112) 5%, rgb(105, 105, 105) 10%, rgb(128, 128, 128) 15%, rgb(139, 139, 139) 20%, rgb(135, 135, 138) 25%, rgb(114, 114, 114) 30%, rgb(109, 109, 109) 35%), 
          repeating-linear-gradient(125deg, rgb(14, 21, 47) 0%, rgb(143, 163, 163) 4%, rgb(143, 193, 193) 4.5%, rgb(143, 163, 163) 5%, rgb(14, 21, 47) 10%, rgb(14, 21, 47) 12%), 
          radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.1) 12%, rgba(0, 0, 0, 0.15) 20%, rgba(0, 0, 0, 0.25) 120%);
        }
        .holo-after {
          background-image: url('https://res.cloudinary.com/fregonatristan/image/upload/v1679587006/SoulsCards/Decks/mat_lswwdq.jpg'), 
          repeating-linear-gradient(0deg, rgb(112, 112, 112) 5%, rgb(105, 105, 105) 10%, rgb(128, 128, 128) 15%, rgb(139, 139, 139) 20%, rgb(135, 135, 138) 25%, rgb(114, 114, 114) 30%, rgb(109, 109, 109) 35%), 
          repeating-linear-gradient(125deg, rgb(14, 21, 47) 0%, rgb(143, 163, 163) 4%, rgb(143, 193, 193) 4.5%, rgb(143, 163, 163) 5%, rgb(14, 21, 47) 10%, rgb(14, 21, 47) 12%), 
          radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.1) 12%, rgba(0, 0, 0, 0.15) 20%, rgba(0, 0, 0, 0.25) 120%);
        }
      }
      &.extra-rare, &.rare, &.matt {
        .holo {
          background-position: center center, 0% 50%, 50% 50%;
          transform-style: preserve-3d;
          transform: translateZ(1px);
          position: absolute;
          top: 0px;
          width: 100%;
          bottom: 0px;
          z-index: 2;
          background-blend-mode: exclusion, hue, hard-light, exclusion;
          background-size: 50%, 200% 700%, 300% 100%, 200% 100%;
        }
        .holo-after {
          background-position: center center, 0% 50%, 50% 50%;
          filter: brightness(0.7) contrast(1.6) saturate(1.4);
          transform-style: preserve-3d;
          transform: translateZ(1px);
          position: absolute;
          top: 0px;
          width: 100%;
          bottom: 0px;
          z-index: 2;
          mix-blend-mode: exclusion;
          background-blend-mode: exclusion, hue, hard-light, exclusion;
          background-size: 50%, 200% 400%, 147% 100%, 200% 100%;
        }
      }
    }
  }
}
</style>
<template>
  <div @wheel.prevent @touchmove.prevent @scroll.prevent class="market-opening">
    <div class="sparks-container">
      <span v-for="spark in 20" :key="spark" :class="`spark-${spark}`"></span>       
    </div>
    <div class="fire"></div>
    <div class="opening-booster-container">
      <div class="back-card pa-1 align-center justify-center">
        <img class="he-100" src="/img/design/back-card.jpg" alt="Card's back"/>
      </div>
      <div :class="[{'opening':opening},'booster']">
        <img v-for="part of ['top','bottom']" :key="part" :src="purchase.deck[`opening_${part}`]" :alt="`Booster’s ${part}`" :class="[`booster-${part}`]"/>
        <div class="holo-wrapper">
          <div class="highlight"></div>
          <div class="holo"></div>
          <div class="holo-after"></div>
        </div>
      </div>
      <sc-card v-for="(card, index) in purchase.cards" :key="index" :card="card" :index="index" :mobile="true"></sc-card>
      <v-btn @click="btn.cb" :disabled="!accessBtn" class="opening-btn secondary white_" x-large>{{btn.text}}</v-btn>
    </div>
    <v-btn v-if="skippable && this.activeIndex !== this.purchase.cards.length - 1" @click="$emit('close')" outlined class="skip-btn">skip</v-btn>
  </div>
</template>

<script>
import { gsap } from 'gsap'

export default {
  props: ['purchase'],
  name: 'opening',
  data() {
    return {
      skippable: true,
      opening: false,
      nextCard: false,
      activeIndex: 0,
      btn: { cb: () => {this.startOpening()}, text: 'open'}
    }
  },
  methods: {
    startOpening() {
      this.opening = true
      this.skippable = false
      const total = this.purchase.cards.length > 12 ? 12 : this.purchase.cards.length
      // button opacity
      gsap.to('.market-opening .opening-btn',{duration: 1, opacity: 0})
      // top opening
      setTimeout(() => {
        gsap.to('.market-opening .booster-top',{duration: 2, transform: 'rotate(-20deg) translate(-10%, -85%)'})
      }, 2000)
      setTimeout(() => {
        gsap.to('.market-opening .booster-top',{duration: 2, opacity: 0})
      }, 3000)
      // cards back to top screen
      setTimeout(() => {
        for(let index = 0; index < total; index++) {
          setTimeout(() => {
              gsap.to('.market-opening .back-card',{duration: 0.5, display: 'flex', transform: 'translate(-50%, -150vh)'})
              gsap.to('.market-opening .back-card',{duration: 0, transform: 'translate(-50%, -50%)'})
          }, 500 * index) // 
        }
      }, 4000)
      // booster transparent
      const duration = 500 * total
      setTimeout(() => {
        gsap.to('.market-opening .booster',{duration: 2, opacity: 0})
      }, 3500 + duration )
      // first card displayed
      setTimeout(() => {
        gsap.to('.market-opening .card-wrapper.card-0',{duration: 0.75, y: '-60%'})
      }, 4000 + duration )
      // cards slider start
      setTimeout(() => {
        this.nextCard = true
        this.skippable = true
        this.btn = {
          cb: () => {this.next()},
          text: 'next card'
        }
        gsap.to('.market-opening .opening-btn',{duration: 1, opacity: 1})
      }, 4500 + duration )
    },
    next() {
      this.nextCard = false
      gsap.to(`.market-opening .card-wrapper.card-${this.activeIndex + 1}`,{duration: 0.75, y: '-60%'})
        setTimeout(() => {
          gsap.to(`.market-opening .card-wrapper.card-${this.activeIndex - 1}`,{duration: 0, opacity: 0})
          this.nextCard = true
        }, 1100 )
        this.activeIndex++
      if (this.activeIndex === this.purchase.cards.length - 1) {
        setTimeout(() => {
          this.btn = {
            cb: () => {this.$emit('close')},
            text: 'close'
          }
        }, 750 )
      }
    }
  },
  computed: {
    accessBtn() {
      if(this.opening && !this.nextCard) return false
      else return true
    }
  }
}
</script>

<style lang="scss">
@property --rotate {
  syntax: "<angle>";
  initial-value: 132deg;
  inherits: false;
}
.market-opening {
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(0, 0, 0);
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  .opening-booster-container {
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    perspective: 1000px;
    .opening-btn {
      margin-top: calc(1em + 16px);
      font-size: 1.25rem!important;
    }
    .card-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -150vh);
      max-height: 359.19px;
      height: 359.19px;
      min-width: 250px;
      width: 250px;
    }
    .back-card {
      display: none;
      height: 35vh;
      background: $secondary;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include desktop {
        height: 30vh;
      }
      &, img {
        border-radius: 8px;
      }
    }
    .booster {
      position: relative;
      animation: booster-animation 10s ease infinite;
      height: 50vh;
      @include desktop {
        height: 45vh;
      }
      .booster-top, .booster-bottom {
        height: 100%;
      }
      .booster-top {
        margin-top: 1px;
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .holo-wrapper {
        opacity: 0.3;
        transition: all ease 1s;
        filter: brightness(0.7) contrast(2) saturate(1.5);
        mix-blend-mode: color-dodge;
        position: absolute;
        z-index: 2;
        top: 0px;
        width: 100%;
        bottom: 0px;
        overflow: hidden;
        backface-visibility: hidden;
        clip-path: polygon(3% 9%, 1% 5%, 1% 0, 99% 0, 99% 5%, 97% 9%, 97% 91%, 100% 95%, 100% 100%, 0 100%, 0 95%, 3% 91%);
        * {
          background-repeat: repeat;
        }
        .highlight {
          position: absolute;
          z-index: 4;
          height: 9vmin;
          width: 9vmin;
          border-radius: 50%;
          background-color: rgba(255, 255, 255);
          filter: brightness(100%) blur(20px);
          left: 10%;
          top: 10%;
        }
        .holo, .holo-after {
          animation: booster-animation-holo 10s ease infinite;
        }
        .holo {
          background-image: url('https://res.cloudinary.com/fregonatristan/image/upload/v1679587005/SoulsCards/Decks/rare_jixaw5.jpg'),
          repeating-linear-gradient(0deg, rgb(255, 102, 102) 5%, rgb(255, 255, 102) 10%, rgb(102, 255, 102) 15%, rgb(102, 255, 255) 20%, rgb(102, 102, 255) 25%, rgb(255, 102, 255) 30%, rgb(255, 102, 102) 35%), 
          repeating-linear-gradient(125deg, rgb(14, 21, 47) 0%, rgb(143, 163, 163) 4%, rgb(143, 193, 193) 4.5%, rgb(143, 163, 163) 5%, rgb(14, 21, 47) 10%, rgb(14, 21, 47) 12%), 
          radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.1) 12%, rgba(0, 0, 0, 0.15) 20%, rgba(0, 0, 0, 0.25) 120%);
          transform-style: preserve-3d;
          transform: translateZ(1px);
          position: absolute;
          top: 0px;
          width: 100%;
          bottom: 0px;
          z-index: 2;
          background-blend-mode: exclusion, hue, hard-light, exclusion;
          background-size: 50%, 200% 700%, 300% 100%, 200% 100%;
          transition: all ease 0.5s;
        }
        .holo-after {
          background-image: url('https://res.cloudinary.com/fregonatristan/image/upload/v1679587005/SoulsCards/Decks/rare_jixaw5.jpg'), 
          repeating-linear-gradient(0deg, rgb(255, 102, 102) 5%, rgb(255, 255, 102) 10%, rgb(102, 255, 102) 15%, rgb(102, 255, 255) 20%, rgb(102, 102, 255) 25%, rgb(255, 102, 255) 30%, rgb(255, 102, 102) 35%), 
          repeating-linear-gradient(125deg, rgb(14, 21, 47) 0%, rgb(143, 163, 163) 4%, rgb(143, 193, 193) 4.5%, rgb(143, 163, 163) 5%, rgb(14, 21, 47) 10%, rgb(14, 21, 47) 12%), 
          radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.1) 12%, rgba(0, 0, 0, 0.15) 20%, rgba(0, 0, 0, 0.25) 120%);
          filter: brightness(0.7) contrast(1.6) saturate(1.4);
          transform-style: preserve-3d;
          transform: translateZ(1px);
          position: absolute;
          top: 0px;
          width: 100%;
          bottom: 0px;
          z-index: 2;
          mix-blend-mode: exclusion;
          background-blend-mode: exclusion, hue, hard-light, exclusion;
          background-size: 50%, 200% 400%, 147% 100%, 200% 100%;
          transition: all ease 0.5s;
        }
      }
      &.opening {
        animation: booster-animation-opening 2s ease forwards;
        .holo-wrapper {
          opacity: 0;
        }
      }
    }
  }
  .sparks-container {
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%;
    height: 100%;
    [class|="spark"] {
      position: absolute;
      width: 15px;
      height: 15px;
      border-radius: 9999px 0 9999px 0;    
    }
    $colors: (#FBB400, #E64601);
    @for $i from 0 through 20 {
      $l: random(110);
      .spark-#{$i} {
        background-color: nth($colors, random(2));
        left: unquote($l+"%");
        bottom: unquote("-"+random(20)+"%");
        animation: flyUp-#{$i} unquote(2+random(6)+"s") infinite;
        transform: rotate(#{random()*360}deg);
      }
      @keyframes flyUp-#{$i} {
        0% {
          opacity:1;
          transform: translateY(0) rotate(#{random()*360}deg);
        }
        50% {
          opacity: unquote(random(100)+"%");
        }
        100% {
          opacity: 0;
          left: unquote($l+random(25)-15+"%");
          transform: translateY(-100vh) rotate(#{random()*360}deg);
        }
      }
    }
  }
  .fire {
    width: 100vw;
    height: 10vh;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    filter: blur(calc(15vh));
    background-image: linear-gradient(var(--rotate), #FBB400, #F09066 43%, #E64601);
    opacity: 1;
    transition: opacity .5s;
    animation: spin 25s linear infinite;
    @keyframes spin {
      0% {
        --rotate: 0deg;
      }
      100% {
        --rotate: 360deg;
      }
    }
  }
  .skip-btn {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0 24px 16px 0;
  }
}
</style>
import { io } from "socket.io-client"
import { api } from '@/../config'
import store from '@/store'

const state = { socketInterval: null }

const actions = {
  START_SOCKET: ({ commit, dispatch }) => {
    try {
      const socket = io(api)
      socket.on('send_notifications', ({notifications}) => {
        const hasNotifs = notifications.friendRequests.length || notifications.historic.length || notifications.trades.length
        const hasNewNotifs = store.getters.getNotifications.friendRequests.length !== notifications.friendRequests.length || store.getters.getNotifications.trades.length !== notifications.trades.length || store.getters.getNotifications.historic.length !== notifications.historic.length
        if(hasNotifs && hasNewNotifs) {
          // displaying notifs
          let notifsToDisplay = []
          for(const slug of ['friendRequests','historic','trades']) {
            const storeNotifs = store.getters.getNotifications[slug]
            const cachedNotifs = notifications[slug]
            if(storeNotifs.length !== cachedNotifs.length) {
              notifsToDisplay = notifsToDisplay.concat(cachedNotifs.filter(n => { return storeNotifs.findIndex(sn => { return sn._id === n._id}) === -1 }))
            }
          }
          for(const [index, notif] of notifsToDisplay.entries()) {
            setTimeout(() => { 
              dispatch('display_notif', notif) 
              setTimeout(() => { dispatch('close_notif') }, 4000)
            }, 5000 * index)
          }
          // update user notifs
          const profile = store.getters.getProfile
          profile.notifications = notifications
          dispatch('USER_UPDATE_PROFILE', {user: profile})
        }
      })
      socket.on('socket_error', (e) => {
        console.log('Socket error')
      })
      commit('START_SOCKET', socket)
    }catch(err) {
      console.log('Socket error')
    }
  },
  STOP_SOCKET: ({ commit }) => {
    try {
      commit('STOP_SOCKET')
    }catch(err) {
      console.log('Socket stopping error')
    }
  }
}

const mutations = {
  ['START_SOCKET']: (state, socket) => {
    state.socketInterval = setInterval(() => {
      socket.emit('get_notifications', {u_id: store.getters.getUserId, socket_id: socket.id})
    }, 20000)
    return
  },
  ['STOP_SOCKET']: state => {
    clearInterval(state.socketInterval)
    state.socketInterval = null
    return
  }
}

export default {
  state,
  actions,
  mutations
}
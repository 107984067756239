import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import SCComponents from "@/components"
import vuetify from './plugins/vuetify'
import VueScrollmagic from 'vue-scrollmagic'
import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import InstantSearch from 'vue-instantsearch'

(async () => {
    axios.defaults.withCredentials = true
  
    Vue.use(SCComponents)
    Vue.use(VueScrollmagic)
    Vue.use(InstantSearch);
    Vue.config.productionTip = false
    
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
})()
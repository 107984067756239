<template>
  <div class="data-trade d-flex flex-column align-center px-2 px-md-6">
    <p class="ma-0 text-caption grey--text">{{authProfile ? 'You :' : 'Offer user :'}}</p>
    <p class="mb-4">{{username}}</p>
    <p class="ma-0 text-caption grey--text">Coins to exchange :</p>
    <div class="f-row f-center">
      <span>{{data.coins}} coins</span>
      <img class="icon-image ml-2 mb-1" src="/img/font/coins.png" alt="Coins"/>
    </div>
    <div v-for="(card, cardIndex) in data.cards" :key="cardIndex" :class="[`card-traded-${orientation}`,'card-traded py-2 py-md-6 f-column align-center']">
      <router-link :to="`/card/${card.title.replaceAll(' ', '-')}/${card._id}`">
        <sc-card-miniature :card="card" :active="true"></sc-card-miniature>
      </router-link>
      <v-btn v-if="!viewOnly" @click="$emit('removeCard', {userField, card, cardIndex})" class="mt-4" small outlined>remove</v-btn>
    </div>
    <v-btn :disabled="drawerBtn" @click="$emit('openDrawer')" class="mt-4" icon outlined><v-icon>mdi-plus</v-icon></v-btn>
  </div>
</template>

<script>
export default {
  props: ['authProfile','data','userField','username','orientation','viewOnly','drawerBtn'],
  name: 'data-trade'
}
</script>

<style lang="scss">
.data-trade {
  padding-bottom: 400px;
  @include desktop {
    .card-traded {
      &-left {
        animation: from-left 1.25s ease infinite;
        animation-iteration-count: 1;
      }
      &-right {
        animation: from-right 1.25s ease infinite;
        animation-iteration-count: 1;
      }
    }
  }
}
</style>
<template>
  <div class="sc-footer">
    <div class="top pa-8 d-flex flex-column flex-wrap flex-sm-row justify-space-around">
      <div v-for="(section, index) in sections" :key="index" class="wi-30 wi-xs-100">
        <p :class="[{'mt-4 mt-sm-0': index === 1},'mb-2 font-weight-bold']">{{section.title}}</p>
        <div v-for="(item, index) in section.list" :key="index">
          <p v-if="item.type === 'text'" class="ma-0 text-caption text-pre-wrap">{{item.text}}</p>
          <router-link v-if="item.type === 'link'" :to="item.link" class="text-caption hover-grey hover-underline">{{item.text}}</router-link>
        </div>
      </div>
    </div>
    <div class="bottom pa-4 f-column f-center">
      <div class="d-flex mb-4">
        <a v-for="(network, index) in networks" :key="index" :href="network.link" target="_blank" class="mx-2 text-caption hover-grey">
          <v-icon small>mdi-{{network.icon}}</v-icon>
          {{network.text}}
        </a>
      </div>
      <p class="ma-0 text-caption">Made by <strong>HollowSTD ∀</strong> {{new Date().getFullYear()}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sc-footer',
  data() {
    return {
      sections: [
        {
          title: 'Legal deposit :',
          list: [
            {type: 'text', text: 'Souls Cards is not affiliated with FromSoftware.\nLogos, names, and cards content are trademarks of FromSoftware.'}
          ]
        },
        {
          title: 'Questions :',
          list: [
            {type: 'link', text: 'Who is behind this webapp ?', link: '/who-am-i'},
            {type: 'link', text: 'How to contact ?', link: '/contact'},
            {type: 'link', text: 'Privacy policy', link: '/privacy-policy'},
          ]
        }
      ],
      networks: [
        {icon: 'twitter', text: '@soulscards', link: 'https://twitter.com'},
        {icon: 'instagram', text: '@soulscards', link: 'https://instagram.com'}
      ]
    }
  }
}
</script>

<style lang="scss">
.sc-footer {
  .top {
    background-color: rgb(5, 5, 5);
  }
  .bottom {
    background-color: black;
  }
}
</style>
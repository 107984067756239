<template>
  <div class="wi-100 he-100 img-uploader d-flex flex-column rounded-lg overflow-hidden">
    <v-file-input v-if="!image" :label="placeholder" class="grey pa-4 pb-0 ma-0" @change="changeImage" clearable></v-file-input>
    <v-text-field v-else :label="placeholder" class="grey pa-4 pb-0 ma-0" @click:clear="$emit('change', null)" :value="imageName" readonly clearable></v-text-field>
    <div class="flex-grow-1 d-flex">
      <img :src="getImage" class="img-uploaded ma-auto"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'img-uploader',
  props: ['image','placeholder'],
  data() {
    return {
      imageFile: ''
    }
  },
  methods : {
    changeImage(file){
      this.imageFile = file
      this.$emit('change', file)
    }
  },
  computed: {
    imageName() {
      return this.image.name || decodeURIComponent(this.image).split('/').pop()
    },
    getImage() {
      if(this.image && typeof this.image === 'object'){
        return URL.createObjectURL(this.image)
      }else{
        return this.image
      }
    }
  }
}
</script>

<style lang="scss">
.img-uploader {
  border: 2px solid $grey;
  .img-uploaded {
    height: 20vh;
  }
}
</style>
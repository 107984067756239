const state = {
  display: false,
  success: false,
  content: {
    text: '',
    color: '',
    btn: '',
    cb: () => { return }
  },
  timeout: 6000
}

const actions = {
  display_infos: ({ commit }, payload) => {
    commit('display_infos', payload)
  },
  close_infos: ({ commit }) => {
    commit('close_infos')
  }
}

const mutations = {
  ['display_infos']: (state, payload) => {
    let contentText = payload.text
    const sessionExpired = payload.error && payload.error.response && payload.error.response.status === 401
    if(sessionExpired) contentText = 'Your session has expired.'
    else if(payload.error && payload.responsePending){
      contentText = 'Internal error.'
      if(payload.error.response && payload.error.response.data && payload.error.response.data.message) contentText = payload.error.response.data.message
      else if(payload.error.data && payload.error.data.message) contentText = payload.error.data.message
    }
    
    state.content = {
      color: payload.color,
      text: contentText,
      btn: !sessionExpired && payload.btn,
      cb: !sessionExpired && payload.cb
    }
    state.success = payload.success === true
    state.timeout = payload.timeout || 6000
    state.display = true
    setTimeout(() => { state.dispaly = false }, payload.timeout || 7000)
  },
  ['close_infos']: (state) => {
    state.display = false
  }
}

export default {
  state,
  actions,
  mutations
}

import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify);

export default new Vuetify({
    theme: { 
      dark: true,
      themes :{
        dark: {
          primary: '#ffffff',
          secondary: '#C39F53',
          alert: '#FF4040'
        }
      }
    }
})

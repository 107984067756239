<template>
  <div class="d-flex flex-column">
    <p class="mb-0 ml-1 caption">By decks :</p>
    <div class="d-flex align-center mt-2" v-for="deck in decks" :key="deck._id">
      <v-checkbox @click="$emit('set', 'decks', deck._id)" :input-value="isFilter('decks', deck._id)" :label="deck.title" dense hide-details></v-checkbox>
      <span class="caption ml-2">({{deck.nbr_cards}})</span>
    </div>
    <v-divider inner></v-divider>
    <p class="mb-0 ml-1 caption">By categories :</p>
    <div class="d-flex align-center mt-2" v-for="category in categories" :key="category._id">
      <v-checkbox @click="$emit('set', 'categories', category._id)" :input-value="isFilter('categories', category._id)" :label="category.title" dense hide-details></v-checkbox>
      <span class="caption ml-2">({{category.nbr_cards}})</span>
    </div>
    <v-divider inner></v-divider>
    <p class="mb-0 ml-1 caption">By rarities :</p>
    <div class="d-flex align-center mt-2" v-for="rarity in rarities" :key="rarity._id">
      <v-checkbox @click="$emit('set', 'rarities', rarity._id)" :input-value="isFilter('rarities', rarity._id)" :label="rarity.title" dense hide-details></v-checkbox>
      <span class="caption ml-2">({{rarity.nbr_cards}})</span>
    </div>
    <v-divider inner></v-divider>
    <v-btn @click="$emit('clear')" outlined small class="mx-auto">clear filters <v-icon right>mdi-close</v-icon></v-btn>
  </div>
</template>

<script>
export default {
  props: ['selectedFilters', 'decks','categories','rarities'],
  name:'filters',
  methods: {
    isFilter(filter, id) {
      return this.selectedFilters[filter].indexOf(id) !== -1
    }
  }
}
</script>

<style>

</style>
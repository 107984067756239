<template>
  <div :class="[{'released':deck.released,'hovered':hovered},'booster-miniature']">
    <div class="booster">
      <img class="booster-image" :src="deck.booster" :alt="deck.title"/>
      <div class="holo-wrapper">
        <div class="holo"></div>
        <div class="holo-after"></div>
      </div>
      <div class="highlight"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['deck','hovered'],
  name: 'booster-miniature',
  data() {
    return {
    }
  }
}
</script>

<style lang="scss">
.booster-miniature .booster, .booster-miniature .booster-image {
  height: 100%;
}
.booster-miniature {
  &:not(.released) {
    .booster, .booster img {
      filter: grayscale(1);
    }
  }
  &.released {
    transition: all 0.5s ease;
    &.hovered {
      transform: translateY(-0.5em) translateX(0.5em);
      .booster {
        transform: rotateY(7.5deg) rotateX(15deg);
        .highlight {
          left: 0;
          top: 20%;
        }
        .holo-wrapper .holo, .holo-wrapper .holo-after {
          background-position: center center, 0% 40%, 36% 40%!important;
        }      
      }
    }
    .booster {
      position: relative;
      overflow: hidden;
      backface-visibility: hidden;
      clip-path: polygon(3% 9%, 1% 5%, 1% 0, 99% 0, 99% 5%, 97% 9%, 97% 91%, 100% 95%, 100% 100%, 0 100%, 0 95%, 3% 91%);
      transition: transform 0.5s ease;
      .highlight {
        position: absolute;
        height: 7.5vmin;
        width: 7.5vmin;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.3);
        filter: brightness(70%) blur(20px);
        left: 10%;
        top: 10%;
        transition: all ease 0.5s;
      }
      .booster-image {
        object-fit: cover;
      }
      .holo-wrapper {
        opacity: 0.3;
        filter: brightness(0.7) contrast(2) saturate(1.5);
        mix-blend-mode: color-dodge;
        position: absolute;
        top: 0px;
        width: 100%;
        bottom: 0px;
        * {
          background-repeat: repeat;
        }
        .holo {
          background-image: url('https://res.cloudinary.com/fregonatristan/image/upload/v1679587005/SoulsCards/Decks/rare_jixaw5.jpg'),
          repeating-linear-gradient(0deg, rgb(255, 102, 102) 5%, rgb(255, 255, 102) 10%, rgb(102, 255, 102) 15%, rgb(102, 255, 255) 20%, rgb(102, 102, 255) 25%, rgb(255, 102, 255) 30%, rgb(255, 102, 102) 35%), 
          repeating-linear-gradient(125deg, rgb(14, 21, 47) 0%, rgb(143, 163, 163) 4%, rgb(143, 193, 193) 4.5%, rgb(143, 163, 163) 5%, rgb(14, 21, 47) 10%, rgb(14, 21, 47) 12%), 
          radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.1) 12%, rgba(0, 0, 0, 0.15) 20%, rgba(0, 0, 0, 0.25) 120%);
          background-position: center center, 0% 50%, 50% 50%;
          transform-style: preserve-3d;
          transform: translateZ(1px);
          position: absolute;
          top: 0px;
          width: 100%;
          bottom: 0px;
          z-index: 2;
          background-blend-mode: exclusion, hue, hard-light, exclusion;
          background-size: 50%, 200% 700%, 300% 100%, 200% 100%;
          transition: all ease 0.5s;
        }
        .holo-after {
          background-image: url('https://res.cloudinary.com/fregonatristan/image/upload/v1679587005/SoulsCards/Decks/rare_jixaw5.jpg'), 
          repeating-linear-gradient(0deg, rgb(255, 102, 102) 5%, rgb(255, 255, 102) 10%, rgb(102, 255, 102) 15%, rgb(102, 255, 255) 20%, rgb(102, 102, 255) 25%, rgb(255, 102, 255) 30%, rgb(255, 102, 102) 35%), 
          repeating-linear-gradient(125deg, rgb(14, 21, 47) 0%, rgb(143, 163, 163) 4%, rgb(143, 193, 193) 4.5%, rgb(143, 163, 163) 5%, rgb(14, 21, 47) 10%, rgb(14, 21, 47) 12%), 
          radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.1) 12%, rgba(0, 0, 0, 0.15) 20%, rgba(0, 0, 0, 0.25) 120%);
          background-position: center center, 0% 50%, 50% 50%;
          filter: brightness(0.7) contrast(1.6) saturate(1.4);
          transform-style: preserve-3d;
          transform: translateZ(1px);
          position: absolute;
          top: 0px;
          width: 100%;
          bottom: 0px;
          z-index: 2;
          mix-blend-mode: exclusion;
          background-blend-mode: exclusion, hue, hard-light, exclusion;
          background-size: 50%, 200% 400%, 147% 100%, 200% 100%;
          transition: all ease 0.5s;
        }
      }
    }
  }
}
</style>
const state = {
  display: false,
  type: '',
  text: ''
}

const actions = {
  display_notif: ({ commit }, payload) => {
    commit('display_notif', payload)
  },
  close_notif: ({ commit }) => {
    commit('close_notif')
  }
}

const mutations = {
  ['display_notif']: (state, payload) => {
    state.display = true
    state.type = payload.type
    state.text = payload.text
  },
  ['close_notif']: (state) => {
    state.display = false
  }
}

export default {
  state,
  actions,
  mutations
}
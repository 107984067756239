<template>
  <button :disabled="disabled" type="submit" :class="[{'disabled':disabled},'btn-submit']" @click="$emit('click')">
    <div class="btn-border">
      <h4 class="f-row f-center">{{text}}<img v-if="icon" class="ml-2 mb-1 icon-image" :src="`/img/font/${icon}.png`" :alt="icon"/></h4>
    </div>
  </button>
</template>

<script>
export default {
  name: 'submit',
  props: ['disabled','text','icon'],
  data() {
    return {
    }
  }
}
</script>

<style lang="scss">
.btn-submit {
    &:not(.disabled) {
      .btn-border:hover h4:before {
        -webkit-transform: scaleX(1);
        -ms-transform: scaleX(1);
        transform: scaleX(1);
      }
      .btn-border {
        background: url('../../../public/img/btn/center.png') top left repeat-x;
        background-size: 31px 52px;
        &:before {
          background: url('../../../public/img/btn/left.png') top left no-repeat;
          background-size: 31px 52px;
        }
        &:after {
          background: url('../../../public/img/btn/right.png') top left no-repeat;
          background-size: 31px 52px;
        }
      }
    }
    &.disabled {
      .btn-border {
        cursor: initial;
        color: grey;
        background: url('../../../public/img/btn/center-grey.png') top left repeat-x;
        background-size: 31px 52px;
        &:before {
          background: url('../../../public/img/btn/left-grey.png') top left no-repeat;
          background-size: 31px 52px;
        }
        &:after {
          background: url('../../../public/img/btn/right-grey.png') top left no-repeat;
          background-size: 31px 52px;
        }
      }
    }
    margin: auto;
    display: inline-block;
    .btn-border {
        cursor: pointer;
        height: 52px;
        z-index: 10;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        margin: 0 31px;
        padding: 16px 7px;
        text-align: center;
        transition: all 0.3s;
      }
    .btn-border:before {
        content: '';
        z-index: 5;
        position: absolute;
        top: 0;
        left: -31px;
        width: 31px;
        height: 52px;
    }
    .btn-border:after {
        content: '';
        z-index: 5;
        position: absolute;
        top: 0;
        right: -31px;
        width: 31px;
        height: 52px;
    }
    .btn-border h4 {
        z-index: 1;
        margin: 0;
        position: relative;
        text-transform: uppercase;
    }
    
    .btn-border h4:before, .btn-border h4:after {
        content: '';
        position: absolute;
        top: -9px;
        left: -28px;
        height: 38px;
        width: calc(100% + 56px);
        z-index: -1;
        background-color: $secondary;
        -webkit-transform: scaleX(0);
        -ms-transform: scaleX(0);
        transform: scaleX(0);
        transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
    }
}
</style>
<template>
  <div class="d-flex align-center justify-center pagination">
    <v-btn :disabled="pagination.page === 0" @click="$emit('changePage', -1)" class="mr-4" icon><v-icon>mdi-chevron-left</v-icon></v-btn>
    <v-select v-model="pagination.page" @change="$emit('updatePage')" :items="pages" outlined dense hide-details>
      <template v-slot:selection="{ item }">
        {{item+1}}
      </template>
      <template v-slot:item="{ item }">
        {{item+1}}
      </template>
    </v-select>
    <v-btn @click="$emit('changePage', 1)" class="ml-4" icon><v-icon>mdi-chevron-right</v-icon></v-btn>
  </div>
</template>

<script>
export default {
  props: ['pagination','pages'],
  name: 'pagination'
}
</script>

<style lang="scss">
.pagination .v-select{
  max-width: min-content;
}
</style>
const state = {
  display: false,
  content: {
      title: '',
      message: '',
      button: '',
      color: '',
      alt: ''
  },
  cb: () => { return },
  altCb: () => { return }
}

const actions = {
  open_dialog: ({ commit }, payload)=>{
      commit('open_dialog', payload)
  },
  close_dialog: ({ commit }) => {
      commit('close_dialog')
  }
}

const mutations = {
  ['open_dialog']: (state, payload) => {
    state.content = {
      title: payload.title || '',
      message: payload.message || '',
      button: payload.button || 'Fermer',
      color: payload.color || '',
      alt: payload.alt || ''
    }
    state.cb = payload.cb || '',
    state.altCb = payload.altCb || ''
    state.display = true
  },
  ['close_dialog']: state => {
      state.display = false
  }
}
  
export default {
  state,
  actions,
  mutations
}
  
<template>
<div class="px-2">
  <v-hover v-slot="{ hover }" class="deck">
    <div>
      <img class="wi-100" :src="deck.thumbnail" :alt="deck.title"/>
      <transition name="slide-fade">          
        <v-overlay v-if="hover" absolute color="transparent">
          <div class="f-column f-center wi-100">
            <v-btn v-if="index !== deckIndex" @click="$emit('select', index)" class="mb-4" outlined>Discover</v-btn>
            <img class="wi-50" :src="deck.logo" :alt="``" />
          </div>
        </v-overlay>
      </transition>
      <v-fade-transition>          
        <v-overlay v-if="decksHover ? !hover : index !== deckIndex" absolute color="#ffffff">
        </v-overlay>
      </v-fade-transition>
    </div>
  </v-hover>
</div>
</template>

<script>
export default {
  props: ['deck', 'index', 'deckIndex', 'decksHover'],
  name: 'deck'
}
</script>

<style lang="scss">
</style>
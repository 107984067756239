<template>    
<div :class="[{'active':active},'card-miniature-wrapper']">
  <v-overlay :absolute="!active" :value="!active" :opacity="0.8" class="rounded-lg"></v-overlay>
  <div :class="[{'full-template':card.rarity.full},getRarity,'card-miniature rounded-lg']" :style="`--deck-color-dark:${hex2rgba(card.deck.color, -50)};--deck-color-light:${hex2rgba(card.deck.color, 25)};`">
    <div class="card-content rounded-lg">
      <img class="wi-100" :src="card.image" :alt="card.title"/>
      <div class="card-infos">
        <p class="card-title text-sc">{{card.title}}</p>
        <div class="d-flex">
          <span class="card-chip category"><img :src="card.category.icon"/>{{card.category.title}}</span>
          <span class="card-chip rarity"><img :src="card.rarity.icon"/>{{card.rarity.title}}</span>
        </div>
      </div>
    </div>
    <div class="holo-wrapper">
      <div class="holo"></div>
      <div class="holo-after"></div>
    </div>
    <div class="highlight"></div>
  </div>    
</div>
</template>

<script>
export default {
  props: ['card','active'],
  name: "card-miniature",
  data() {
    return {
    }
  },
  methods: {
    hex2rgba(col, amt) {
      col = col.replace(/^#/, '')
      if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]
      let [r, g, b] = col.match(/.{2}/g);
      ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])
      r = Math.max(Math.min(255, r), 0).toString(16)
      g = Math.max(Math.min(255, g), 0).toString(16)
      b = Math.max(Math.min(255, b), 0).toString(16)
      const rr = (r.length < 2 ? '0' : '') + r
      const gg = (g.length < 2 ? '0' : '') + g
      const bb = (b.length < 2 ? '0' : '') + b
      return `#${rr}${gg}${bb}`
    }
  },
  computed: {
    getRarity() {
      return this.card.rarity.title.replace(' ', '-')
    }
  }
}
</script>

<style lang="scss">
.card-miniature-wrapper {
  perspective: 1000px;
  filter: drop-shadow(-5px 2.5px 5px rgba(145, 145, 145, 0.3))!important;
  transition: all 0.5s ease;
  &.active:hover {
    transform: translateY(-0.5em) translateX(0.5em);
    filter: drop-shadow(-20px 10px 5px rgba(124, 124, 124, 0.3))!important;
    .card-miniature {
      transform: rotateY(7.5deg)  rotateX(15deg);
      .highlight {
        left: 0;
        top: 20%;
      }
      .holo-wrapper .holo, .holo-wrapper .holo-after {
        background-position: center center, 0% 40%, 36% 40%!important;
      }      
    }
  }
  .card-miniature {
    position: relative;
    background: $secondary;
    padding: 2px;
    width: 200px;
    @include mobile {
      padding: 2px;
      width: 150px;
    }
    overflow: hidden;
    backface-visibility: hidden;
    transition: transform 0.5s ease;
    &.full-template {
      .card-infos {
        padding: 0 20px 25px 20px;
        @include mobile {
          padding: 0 15px 20px 15px;
        }
      }
    }
    &:not(.full-template) {
      .card-infos {
        padding: 0 8px 6px 8px;
        @include mobile {
          padding: 0 6px 5px 6px;
        } 
      }
    }
    .card-content {
      min-height: 282.17px;
      background: linear-gradient(180deg, var(--deck-color-light) 0%, var(--deck-color-dark) 100%);
      padding: 8px 8px 0 8px;
      @include mobile {
        min-height: 212.14px;
        padding: 6px 6px 0 6px;
      } 
      .card-infos {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        .card-title {
          text-shadow: 0px 0px 6px #000000;
          font-size: 12.5px;
          text-align: center;
          margin: 0;
          margin-bottom: 8px;
          padding-bottom: 2px;
          position: relative;
          @include mobile {
            font-size: 9.4px;
            margin-bottom: 7px;
            padding-bottom: 3px;
          }
          &:after {    
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
            bottom: 0;
            content: "";
            display: block;
            height: 2px;
            left: 10%;
            position: absolute;
            background: #fff;
            width: 80%;
            @include mobile {
              height: 1px;
            }
          }
        }
        .card-chip {
          font-size: 9px;
          border: 1px solid;
          padding: 0 4px;
          border-radius: 9999px;
          background: rgba(37, 37, 37, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          @include mobile {
            font-size: 6.75px;
            padding: 0 3px;
          }
          &.category {
            color: white;
            border-color: white;
            margin: 0 2px 0 4px;
            @include mobile {
              margin: 0 1.5px 0 3px;
            }
          }
          &.rarity {
            color: $secondary;
            border-color: $secondary;
            margin-left: 2px;
            @include mobile {
              margin-left: 1.5px;
            }
          }
          &.rarity img, &.category img {
            margin-right: 2px;
            height: 9px;
            @include mobile {
              height: 6.75px;
            }
          }
        }
      }
    }
    .highlight {
      position: absolute;
      height: 7.5vmin;
      width: 7.5vmin;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.3);
      filter: brightness(70%) blur(20px);
      left: 10%;
      top: 10%;
      transition: all ease 0.5s;
    }
    &.extra-rare, &.rare, &.matt {
      .holo-wrapper {
        opacity: 0.3;
        filter: brightness(0.7) contrast(2) saturate(1.5);
        mix-blend-mode: color-dodge;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        bottom: 0px;
        * {
          background-repeat: repeat;
        }
        .holo {
          background-position: center center, 0% 50%, 50% 50%;
          transform-style: preserve-3d;
          transform: translateZ(1px);
          position: absolute;
          top: 0px;
          width: 100%;
          bottom: 0px;
          z-index: 2;
          background-blend-mode: exclusion, hue, hard-light, exclusion;
          background-size: 50%, 200% 700%, 300% 100%, 200% 100%;
          transition: all ease 0.5s;
        }
        .holo-after {
          background-position: center center, 0% 50%, 50% 50%;
          filter: brightness(0.7) contrast(1.6) saturate(1.4);
          transform-style: preserve-3d;
          transform: translateZ(1px);
          position: absolute;
          top: 0px;
          width: 100%;
          bottom: 0px;
          z-index: 2;
          mix-blend-mode: difference;
          background-blend-mode: exclusion, hue, hard-light, exclusion;
          background-size: 50%, 200% 400%, 147% 100%, 200% 100%;
          transition: all ease 0.5s;
        }
      }
    }
    &.rare {
      .holo {
        background-image: url('https://res.cloudinary.com/fregonatristan/image/upload/v1679587005/SoulsCards/Decks/rare_jixaw5.jpg'),
        repeating-linear-gradient(0deg, rgb(255, 102, 102) 5%, rgb(255, 255, 102) 10%, rgb(102, 255, 102) 15%, rgb(102, 255, 255) 20%, rgb(102, 102, 255) 25%, rgb(255, 102, 255) 30%, rgb(255, 102, 102) 35%), 
        repeating-linear-gradient(125deg, rgb(14, 21, 47) 0%, rgb(143, 163, 163) 4%, rgb(143, 193, 193) 4.5%, rgb(143, 163, 163) 5%, rgb(14, 21, 47) 10%, rgb(14, 21, 47) 12%), 
        radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.1) 12%, rgba(0, 0, 0, 0.15) 20%, rgba(0, 0, 0, 0.25) 120%);
      }
      .holo-after {
        background-image: url('https://res.cloudinary.com/fregonatristan/image/upload/v1679587005/SoulsCards/Decks/rare_jixaw5.jpg'), 
        repeating-linear-gradient(0deg, rgb(255, 102, 102) 5%, rgb(255, 255, 102) 10%, rgb(102, 255, 102) 15%, rgb(102, 255, 255) 20%, rgb(102, 102, 255) 25%, rgb(255, 102, 255) 30%, rgb(255, 102, 102) 35%), 
        repeating-linear-gradient(125deg, rgb(14, 21, 47) 0%, rgb(143, 163, 163) 4%, rgb(143, 193, 193) 4.5%, rgb(143, 163, 163) 5%, rgb(14, 21, 47) 10%, rgb(14, 21, 47) 12%), 
        radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.1) 12%, rgba(0, 0, 0, 0.15) 20%, rgba(0, 0, 0, 0.25) 120%);
      }
    }
    &.extra-rare {
      .holo {
        background-image: url('https://res.cloudinary.com/fregonatristan/image/upload/v1679586401/SoulsCards/Decks/extra_rare_s2gvc2.jpg'),
        repeating-linear-gradient(0deg, rgb(255, 102, 102) 5%, rgb(255, 255, 102) 10%, rgb(102, 255, 102) 15%, rgb(102, 255, 255) 20%, rgb(102, 102, 255) 25%, rgb(255, 102, 255) 30%, rgb(255, 102, 102) 35%), 
        repeating-linear-gradient(125deg, rgb(14, 21, 47) 0%, rgb(143, 163, 163) 4%, rgb(143, 193, 193) 4.5%, rgb(143, 163, 163) 5%, rgb(14, 21, 47) 10%, rgb(14, 21, 47) 12%), 
        radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.1) 12%, rgba(0, 0, 0, 0.15) 20%, rgba(0, 0, 0, 0.25) 120%);
      }
      .holo-after {
        background-image: url('https://res.cloudinary.com/fregonatristan/image/upload/v1679586401/SoulsCards/Decks/extra_rare_s2gvc2.jpg'), 
        repeating-linear-gradient(0deg, rgb(255, 102, 102) 5%, rgb(255, 255, 102) 10%, rgb(102, 255, 102) 15%, rgb(102, 255, 255) 20%, rgb(102, 102, 255) 25%, rgb(255, 102, 255) 30%, rgb(255, 102, 102) 35%), 
        repeating-linear-gradient(125deg, rgb(14, 21, 47) 0%, rgb(143, 163, 163) 4%, rgb(143, 193, 193) 4.5%, rgb(143, 163, 163) 5%, rgb(14, 21, 47) 10%, rgb(14, 21, 47) 12%), 
        radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.1) 12%, rgba(0, 0, 0, 0.15) 20%, rgba(0, 0, 0, 0.25) 120%);
      }
    }
    &.matt {
      .holo {
        background-image: url('https://res.cloudinary.com/fregonatristan/image/upload/v1679587006/SoulsCards/Decks/mat_lswwdq.jpg'),
        repeating-linear-gradient(0deg, rgb(112, 112, 112) 5%, rgb(105, 105, 105) 10%, rgb(128, 128, 128) 15%, rgb(139, 139, 139) 20%, rgb(135, 135, 138) 25%, rgb(114, 114, 114) 30%, rgb(109, 109, 109) 35%), 
        repeating-linear-gradient(125deg, rgb(14, 21, 47) 0%, rgb(143, 163, 163) 4%, rgb(143, 193, 193) 4.5%, rgb(143, 163, 163) 5%, rgb(14, 21, 47) 10%, rgb(14, 21, 47) 12%), 
        radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.1) 12%, rgba(0, 0, 0, 0.15) 20%, rgba(0, 0, 0, 0.25) 120%);
      }
      .holo-after {
        background-image: url('https://res.cloudinary.com/fregonatristan/image/upload/v1679587006/SoulsCards/Decks/mat_lswwdq.jpg'), 
        repeating-linear-gradient(0deg, rgb(112, 112, 112) 5%, rgb(105, 105, 105) 10%, rgb(128, 128, 128) 15%, rgb(139, 139, 139) 20%, rgb(135, 135, 138) 25%, rgb(114, 114, 114) 30%, rgb(109, 109, 109) 35%), 
        repeating-linear-gradient(125deg, rgb(14, 21, 47) 0%, rgb(143, 163, 163) 4%, rgb(143, 193, 193) 4.5%, rgb(143, 163, 163) 5%, rgb(14, 21, 47) 10%, rgb(14, 21, 47) 12%), 
        radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.1) 12%, rgba(0, 0, 0, 0.15) 20%, rgba(0, 0, 0, 0.25) 120%);
      }
    }
  }
}
</style>
<template>
  <div class="users-table wi-100 d-flex flex-column">
    <sc-pagination v-if="pages.length > 1" class="pb-8" v-bind="{pagination, pages}" @changePage="$emit('changePage', $event)" @updatePage="$emit('getUsers')"></sc-pagination>
    <v-data-table class="users-table wi-80 wi-sm-100 wi-xs-100 mx-auto" @click:row="$event._id && $router.push(`/user/${$event.username.replaceAll(' ', '-')}/${$event._id}`)" :headers="headers" :items="users" :loading="loading" loading-text="Loading users..." :items-per-page="50" hide-default-footer>
      <template v-slot:[`item.icon`]="{ item }">
        <v-avatar v-if="item.icon" class="my-2">
          <img :src="item.icon.image"/>
        </v-avatar>
        <v-avatar v-else class="my-2">
        </v-avatar>
      </template>
      <template v-slot:[`item.username`]="{ item, index }">
        <p v-if="item.username" class="ma-0">#{{pagination.page*50+1+index}} {{item.username}}</p>
      </template>
      <template v-slot:[`item.coins`]="{ item }">
        <div v-if="item.coins >= 0" class="d-flex align-center">
          {{item.coins}}
          <img class="icon-image ml-2 mb-1" src="/img/font/coins.png" alt="icon"/>
        </div>
      </template>
      <template v-slot:[`item.cards`]="{ item }">
        <div v-if="item.cards >= 0" class="d-flex align-center">
          {{item.cards}}
          <img class="icon-image ml-2 mb-1" src="/img/font/cards.png" alt="icon"/>
        </div>
      </template>
      <template v-slot:[`item.trades`]="{ item }">
        <div v-if="item.trades >= 0" class="d-flex align-center">
          {{item.trades}}
          <img class="icon-image ml-2 mb-1" src="/img/font/trades.png" alt="icon"/>
        </div>
      </template>
      <template v-slot:[`item.creation_date`]="{ item }">
        <p v-if="item.creation_date" class="ma-0">{{new Date(item.creation_date).toLocaleDateString('en-GB', { month: 'numeric', day: 'numeric', year: 'numeric' })}}</p>
      </template>
    </v-data-table>
    <sc-pagination v-if="pages.length > 1" class="pt-8" v-bind="{pagination, pages}" @changePage="$emit('changePage', $event)" @updatePage="$emit('getUsers')"></sc-pagination>
  </div>
</template>

<script>
export default {
  props: ['users','loading','pagination','pages'],
  name: 'users-table',
  data() {
    return {
      headers: [
        {text: '', align: 'start', value: 'icon', sortable: false},
        {text: 'Username', align: 'start', value: 'username', sortable: false},
        {text: 'Coins', align: 'start', value: 'coins', sortable: false},
        {text: 'Cards', align: 'start', value: 'cards', sortable: false},
        {text: 'Trades', align: 'start', value: 'trades', sortable: false},
        {text: '', align: 'end', value: '', sortable: false},
        {text: 'Inscription', align: 'end', value: 'creation_date', sortable: false}
      ]
    }
  }
}
</script>

<style lang="scss">
.users-table {
  tbody {
    tr {
      cursor: pointer;
      &:hover td:nth-child(2){
        text-decoration: underline;
      }
    }
    td {
      font-size: 1rem!important;
    }
  }
}
</style>
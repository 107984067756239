<template>
  <div class="loading-card d-flex align-center justify-center">
    <img class="back-card wi-100" src="/img/design/back-card.jpg" alt="Card's back"/>
    <v-skeleton-loader class="he-100" type="image"></v-skeleton-loader>
  </div>
</template>

<script>
export default {
  name: 'loading-card'
}
</script>

<style lang="scss">
.loading-card {
  width: 200px;
  padding: 4px;
  background-color: $secondary;
  position: relative;
  &, .back-card, .v-skeleton-loader {
    border-radius: 8px;
  }
  .v-skeleton-loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    .v-skeleton-loader__image {
      height: 100%;
    }
  }
}
</style>
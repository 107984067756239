<template>
  <v-app>
    <sc-global-loader v-if="$store.state.status.globalLoading"></sc-global-loader>
    <v-dialog v-model="$store.state.dialog.display" :width="['sm', 'xs'].includes($vuetify.breakpoint.name) ? '90vw':'50vw'" persistent>
      <sc-dialog v-if="$store.state.dialog.display" v-bind="$store.state.dialog"></sc-dialog>
    </v-dialog>
    <v-slide-x-reverse-transition>
      <sc-notification v-if="$store.state.notif.display" v-bind="$store.state.notif"></sc-notification>
    </v-slide-x-reverse-transition>
    <sc-nav-bar :navItems="navItems" @drawer="drawer = !drawer"></sc-nav-bar>
    <sc-drawer :navItems="navItems" v-model="drawer" @input="drawer = $event"></sc-drawer>
    <v-main v-if="!loadingApp" style="padding: 0">
      <transition name="fade">
        <router-view :key="$route.fullPath" />
      </transition>
    </v-main>
    <div v-else class="content-loader">
      <img src="/img/content-loader.gif" alt="content loader gif"/>
    </div>
    <sc-footer></sc-footer>
    <sc-snackbar v-bind="$store.state.infos"></sc-snackbar>
  </v-app>
</template>

<script>
import { recaptcha } from '@/../config'
import axios from 'axios'
import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'
Vue.use(VueReCaptcha, { siteKey: recaptcha })

export default {
  name: 'app',
  data:()=>{
    return {
      loadingApp: true,
      drawer: false
    }
  },
  watch: {
    '$route' (to) {
      document.title = to.meta.title || 'Souls Cards'
    }
  },
  async created() {
    axios.interceptors.response.use(async response => {
      if(response.headers['new-token']){
        await this.$store.dispatch('UPDATE_TOKEN',response.headers['new-token'])
      }
      return response
    }, async (error) => {
      if (error.response.status === 401) {
        this.$store.dispatch('reset_pending')
        if (this.$store.getters.isProfileLoaded) await this.$store.dispatch('AUTH_LOGOUT')
      }
      return Promise.reject(error)
    })
    if(this.$store.getters.isAuthenticated) {
      axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-token')
      await this.$store.dispatch('USER_REQUEST')
      this.$store.dispatch('START_SOCKET')
    }
    this.loadingApp = false
  },
  computed: {
    navItems() {
      return [
        {text: 'Decks', link: '/decks'},
        {text: 'Cards', link: '/cards'},
        {text: 'Users', link: '/users'},
        {text: 'Market', link: '/marketplace'}
      ]
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import 'scss/style.scss';

#app {
  width: 100%;
  min-height: 100vh;
  font-family: 'Sofia Sans', Helvetica, Arial, sans-serif;
  [class*='text-']:not(.text-sc) {
    font-family: 'Sofia Sans', Helvetica, Arial, sans-serif!important;
  }
  .text-sc {
    font-family: "Optimus", serif!important;
  }
}
.v-application--wrap {
  background-color: $black;
  color: white;
}
</style>
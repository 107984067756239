<template>
  <div class="dialog-container">
    <div class="text-center dialog-content">
      <img v-for="index in 4" :key="index" class="dialog-corner" src="/img/btn/corner.svg" alt="Corner style"/>
      <div v-html="content.title"></div>
      <div v-html="content.message"></div>
      <v-divider class="mt-8"></v-divider>
      <div class="wi-100">
          <v-btn :class="[(content.color ? content.color : 'primary--text'),'white--text']" @click="callback()" outlined>{{content.button || "Close"}}</v-btn>
      </div>
      <div v-if="content.alt && content.alt.length">
        <v-btn @click="altCallback()" text class="mt-2 grey--text text-decoration-underline">{{content.alt}}</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dialog-popup',
  props:['content','cb','altCb'],
  methods:{
      handleEnter: function(e){
          if(e.code === 'Enter'){
              this.callback()
          }
      },
      callback:function(){
          if(this.cb){
              this.cb()
          }
          this.$store.dispatch('close_dialog')
      },
      altCallback:function(){
          if(this.altCb){
              this.altCb()
          }
          this.$store.dispatch('close_dialog')
      }
  }
}
</script>

<style lang="scss">
.dialog-container {
  background: $blacklight;
  border-radius: 1em;
  .dialog-content {
    position: relative;
    padding: 2.5em 2.5em 1em 2.5em;
    .dialog-corner {
      position: absolute;
      height: 3.5em;
      width: 3.5em;
      &:first-child {
        top: 0.75em;
        left: 0.75em;
        transform: rotate(90deg);
      }
      &:nth-child(2) {
        top: 0.75em;
        right: 0.75em;
        transform: rotate(180deg);
      }
      &:nth-child(3) {
        bottom: 0.75em;
        right: 0.75em;
        transform: rotate(-90deg);
      }
      &:nth-child(4) {
        bottom: 0.75em;
        left: 0.75em;
      }
    }
    .highlight {
      color: $secondary;
      font-weight: bold;
    }
  }
}
</style>

<template>
  <div v-if="loader && $store.state.status.contentLoading">
    <div class="content-loader">
      <img src="/img/content-loader.gif" alt="content loader gif"/>
    </div>
  </div>
  <div v-else :class="[{'nav-bar-top':top,'px-4 px-md-8':paddingX,'pb-5 pb-md-10':paddingBottom},'min-height-screen d-flex flex-column']">
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: 'page-template',
  props: {
    paddingX: {
      type: Boolean,
      required: false,
      default: true
    },
    paddingBottom: {
      type: Boolean,
      required: false,
      default: true
    },
    top: {
      type: Boolean,
      required: false,
      default: false
    },
    loader: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss">
.content-loader {
  height: 100vh;
  width: 100%;
  background: #101010;
  position: relative;
  img {
    @include noMobile {
      height: 30vh;
      width: auto;
    }
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>

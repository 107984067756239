<template>
  <div class="friend-requests flex-grow-1 d-flex flex-column">
    <v-tabs v-model="tab" background-color="transparent" class="mb-4 flex-grow-0" grow>
      <v-tab v-for="item in items" :key="item">
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <div v-if="loading" class="d-flex mx-8">
          <v-progress-circular class="mx-auto" size="40" indeterminate color="secondary"></v-progress-circular>
        </div>
        <p v-else-if="!friendRequests.received.length" class="mx-8 text-center">No friend request received</p>
        <v-container v-else class="pa-0">
          <v-row v-for="request in friendRequests.received" :key="request._id" no-gutters>
            <v-col cols="3" class="d-flex align-center">
              <v-badge :value="cachedNewRequests.some(n => { return n._id === request._id })" color="secondary" offset-y="27" offset-x="17" overlap>
                <template v-slot:badge>
                  <span>New</span>
                </template>
                <v-avatar class="my-2">
                  <img :src="request.from.icon.image"/>
                </v-avatar>
              </v-badge>
            </v-col>
            <v-col cols="9">
              <v-row no-gutters>
                <v-col cols="6" class="d-flex">
                  <router-link :to="`/user/${request.from.username.replaceAll(' ', '-')}/${request.from._id}`" class="my-auto hover-underline">{{request.from.username}}</router-link>
                </v-col>                
                <v-col cols="6" class="d-flex justify-end">
                  <p class="my-auto text-caption">{{new Date(request.creation_date).toLocaleDateString('en-GB', { month: 'numeric', day: 'numeric', year: 'numeric' })}}</p>
                </v-col>
                <v-col cols="12" class="d-flex pt-2">
                  <v-btn @click="updateRequest('accepted', request._id)" class="ml-auto mr-2 green" small>accept</v-btn>
                  <v-btn @click="updateRequest('declined', request._id)" class="mr-auto ml-2 red--text" outlined small>decline</v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <div v-if="loading" class="d-flex mx-8">
          <v-progress-circular class="mx-auto" size="40" indeterminate color="secondary"></v-progress-circular>
        </div>
        <p v-else-if="!friendRequests.sent.length" class="mx-8 text-center">No friend request sent</p>
        <v-container v-else class="pa-0">
          <v-row v-for="request in friendRequests.sent" :key="request._id" no-gutters>
            <v-col cols="9" class="f-column justify-center">
              <p class="my-auto text-caption request-date">Sent the {{new Date(request.creation_date).toLocaleDateString('en-GB', { month: 'numeric', day: 'numeric', year: 'numeric' })}}</p>
              <router-link :to="`/user/${request.to.username.replaceAll(' ', '-')}/${request.to._id}`" class="hover-underline">{{request.to.username}}</router-link>
            </v-col>
            <v-col cols="3" class="d-flex align-center justify-end">
              <v-avatar class="my-2">
                <img :src="request.to.icon.image"/>
              </v-avatar>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>

    </v-tabs-items>
  </div>
</template>

<script>
import axios from 'axios'
import { api } from '@/../config'

export default {
  name: 'friend-requests',
  data() {
    return {
      loading: true,
      tab: null,
      items: ['received','sent'],
      cachedNewRequests: [],
      friendRequests: {
        received: [],
        sent: []
      }
    }
  },
  created() {
    this.getFriendRequests()
    if(this.$store.getters.getNotifications.friendRequests.length) {
      this.cachedNewRequests = this.$store.getters.getNotifications.friendRequests
      this.removeNotifs()
    }
  },
  methods: {
    async getFriendRequests() {
      try {
        this.loading = true
        const res = await axios.get(`${api}auth/friend-requests`)
        this.friendRequests = res.data
        this.loading = false
      } catch(e) {
        this.$store.dispatch('display_infos', {error: e, text: 'Error while getting your friend requests. Refresh or try later.', btn: 'refresh', cb: () => { this.$router.go()}})
      }
    },
    async updateRequest(response, id) {
      try {
        this.loading = true
        await this.$recaptchaLoaded()
        const recaptcha = await this.$recaptcha('checkCaptcha')
        await axios.put(`${api}auth/friend-request/${id}`, {response, recaptcha: recaptcha})
        this.getFriendRequests()
        if(response === 'accepted') this.$emit('getFriends')
      } catch(e) {
        this.$store.dispatch('display_infos', {error: e, text: 'Error while responding to the request. Refresh or try later.', btn: 'refresh', cb: () => { this.$router.go()}})
      }
    },
    async removeNotifs() {
      try {
        await this.$recaptchaLoaded()
        const recaptcha = await this.$recaptcha('updateProfile')
        await this.$store.dispatch('remove_notifications',{type: 'friendRequests', recaptcha: recaptcha})
      } catch(e) {
        // do nothing
      }
    }
  }
}
</script>

<style lang="scss">
.friend-requests {
  .v-tabs-items {
    margin-top: 16px;
    background-color: transparent!important;
    overflow: visible;
  }
  .request-date {
    margin-right: 80px;
  }
}
</style>